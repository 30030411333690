import {BodySchemeRestDto} from "@/api/pharma-cms-color-scheme/models";
import {ColorRestDtoModel} from "@/models/api/ColorRestDtoModel";

export class BodySchemeRestDtoModel implements BodySchemeRestDto {
    background_color: ColorRestDtoModel | null;
    line_height_size_in_perc: number | null;
    root_font_size_in_px: number | null;
    text_color: ColorRestDtoModel | null;


    constructor(background_color: ColorRestDtoModel
                , line_height_size_in_perc: number
                , root_font_size_in_px: number
                , text_color: ColorRestDtoModel
    ) {
        this.background_color = background_color;
        this.line_height_size_in_perc = line_height_size_in_perc;
        this.root_font_size_in_px = root_font_size_in_px;
        this.text_color = text_color;
    }

    public static createWithDefaults(): BodySchemeRestDtoModel {
        return new BodySchemeRestDtoModel(ColorRestDtoModel.createWithDefaults(), 100, 16, ColorRestDtoModel.createWithDefaults());
    }
}
