import {ColorSchemeRestDto} from "@/api/pharma-content-customer/models";

export class ColorSchemeRestDtoModel implements ColorSchemeRestDto {
    primary_color: string;
    primary_lighter_color: string;
    primary_light_color: string;
    secondary_color: string;
    secondary_lighter_color: string;
    secondary_light_color: string;
    tertiary_color: string;
    tertiary_lighter_color: string;
    tertiary_light_color: string;
    text_color_on_primary_color: string;
    text_color_on_primary_lighter_color: string;
    text_color_on_secondary_color: string;
    text_color_on_secondary_lighter_color: string;
    text_color_on_tertiary_color: string;
    text_color_on_tertiary_lighter_color: string;
    text_color_on_light_color: string;

    constructor(color_scheme: ColorSchemeRestDto) {
        this.primary_color = color_scheme.primary_color;
        this.primary_lighter_color = color_scheme.primary_lighter_color;
        this.primary_light_color = color_scheme.primary_light_color;
        this.secondary_color = color_scheme.secondary_color;
        this.secondary_lighter_color = color_scheme.secondary_lighter_color;
        this.secondary_light_color = color_scheme.secondary_light_color;
        this.tertiary_color = color_scheme.tertiary_color;
        this.tertiary_lighter_color = color_scheme.tertiary_lighter_color;
        this.tertiary_light_color = color_scheme.tertiary_light_color;
        this.text_color_on_primary_color = color_scheme.text_color_on_primary_color;
        this.text_color_on_primary_lighter_color = color_scheme.text_color_on_primary_lighter_color;
        this.text_color_on_secondary_color = color_scheme.text_color_on_secondary_color;
        this.text_color_on_secondary_lighter_color = color_scheme.text_color_on_secondary_lighter_color;
        this.text_color_on_tertiary_color = color_scheme.text_color_on_tertiary_color;
        this.text_color_on_tertiary_lighter_color = color_scheme.text_color_on_tertiary_lighter_color;
        this.text_color_on_light_color = color_scheme.text_color_on_light_color;
    }

    public static createWithDefaults(): ColorSchemeRestDtoModel {
        return new ColorSchemeRestDtoModel({
            primary_color: '#000',
            primary_lighter_color: '#000',
            primary_light_color: '#000',
            secondary_color: '#000',
            secondary_lighter_color: '#000',
            secondary_light_color: '#000',
            tertiary_color: '#000',
            tertiary_lighter_color: '#000',
            tertiary_light_color: '#000',
            text_color_on_primary_color: '#fff',
            text_color_on_primary_lighter_color: '#fff',
            text_color_on_secondary_color: '#fff',
            text_color_on_secondary_lighter_color: '#fff',
            text_color_on_tertiary_color: '#fff',
            text_color_on_tertiary_lighter_color: '#fff',
            text_color_on_light_color: '#fff'
        });
    }
}
