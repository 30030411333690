import {ButtonRestDto} from "@/api/pharma-cms-content-block/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {ButtonTargetEnum} from "@/api/enums/button-target-enum";
import {ButtonLayoutEnum} from "@/api/enums/button-layout-enum";
import {MaterialSymbolRestDtoModel} from "@/models/api/pharma-cms-content-block/MaterialSymbolRestDtoModel";

export class ButtonRestDtoModel implements ButtonRestDto {
    button_layout_code?: ButtonLayoutEnum;
    button_page_id?: MultilingualRestDtoModel | null;
    button_target?: ButtonTargetEnum | null;
    symbol_left?: MaterialSymbolRestDtoModel | null;
    button_text: MultilingualRestDtoModel;
    symbol_right?: MaterialSymbolRestDtoModel | null;
    button_url?: MultilingualRestDtoModel | null;

    constructor(button_text: MultilingualRestDtoModel, button_target?: ButtonTargetEnum | null, button_url?: MultilingualRestDtoModel | null) {
        this.button_text = button_text;
        this.button_target = button_target ?? undefined;
        this.button_url = button_url ?? undefined;
    }

    public static createWithDefaults(): ButtonRestDtoModel {
        return new ButtonRestDtoModel(
            MultilingualRestDtoModel.createWithDefaults(),
            ButtonTargetEnum.BLANK,
            MultilingualRestDtoModel.createWithDefaults(),
        );
    }
}
