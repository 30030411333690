
import {defineComponent} from "vue";

export default defineComponent({
  name: "AdvancedPanel",
  computed: {
    isShowPanel() : Boolean {
      return (this.isShowWhenAdvanced && this.isAdvanced) || (!this.isShowWhenAdvanced && !this.isAdvanced);
    },
  },
  props: {
    isShowWhenAdvanced: {
      type: Boolean,
      required: true,
    },
    isAdvanced: {
      type: Boolean,
      required: true,
    },
    class: {
      type: String,
      required: false
    }
  },
});
