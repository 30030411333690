/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Color Scheme API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { TitleMetaDataRestDto } from '../models';
/**
 * TitleSchemeEndpointsApi - axios parameter creator
 * @export
 */
export const TitleSchemeEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.TS.01 Get the title metadata for title size for this current customer.
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} title_size_code The code that uniquely identifies the title size.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTitleMetaForSize: async (x_dp_customer_code: string, title_size_code: string, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling getTitleMetaForSize.');
            }
            // verify required parameter 'title_size_code' is not null or undefined
            if (title_size_code === null || title_size_code === undefined) {
                throw new RequiredError('title_size_code','Required parameter title_size_code was null or undefined when calling getTitleMetaForSize.');
            }
            const localVarPath = `/color_scheme/v1/title_scheme/title_meta/{title_size_code}`
                .replace(`{${"title_size_code"}}`, encodeURIComponent(String(title_size_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.TS.03 Update title metadata scheme for customer to default values for specific title size code.
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} title_size_code The code that uniquely identifies the title size.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        titleMetaForSizeToDefaults: async (x_dp_customer_code: string, title_size_code: string, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling titleMetaForSizeToDefaults.');
            }
            // verify required parameter 'title_size_code' is not null or undefined
            if (title_size_code === null || title_size_code === undefined) {
                throw new RequiredError('title_size_code','Required parameter title_size_code was null or undefined when calling titleMetaForSizeToDefaults.');
            }
            const localVarPath = `/color_scheme/v1/title_scheme/title_meta/{title_size_code}/to_defaults`
                .replace(`{${"title_size_code"}}`, encodeURIComponent(String(title_size_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.TS.02 Update title metadata scheme of a specific title size code for customer .
         * @param {TitleMetaDataRestDto} body 
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} title_size_code The code that uniquely identifies the title size.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTitleMetaForSize: async (body: TitleMetaDataRestDto, x_dp_customer_code: string, title_size_code: string, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateTitleMetaForSize.');
            }
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling updateTitleMetaForSize.');
            }
            // verify required parameter 'title_size_code' is not null or undefined
            if (title_size_code === null || title_size_code === undefined) {
                throw new RequiredError('title_size_code','Required parameter title_size_code was null or undefined when calling updateTitleMetaForSize.');
            }
            const localVarPath = `/color_scheme/v1/title_scheme/title_meta/{title_size_code}`
                .replace(`{${"title_size_code"}}`, encodeURIComponent(String(title_size_code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TitleSchemeEndpointsApi - functional programming interface
 * @export
 */
export const TitleSchemeEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.TS.01 Get the title metadata for title size for this current customer.
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} title_size_code The code that uniquely identifies the title size.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTitleMetaForSize(_axios: AxiosInstance, x_dp_customer_code: string, title_size_code: string, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TitleMetaDataRestDto>> {
            const localVarAxiosArgs = await TitleSchemeEndpointsApiAxiosParamCreator(configuration).getTitleMetaForSize(x_dp_customer_code, title_size_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.TS.03 Update title metadata scheme for customer to default values for specific title size code.
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} title_size_code The code that uniquely identifies the title size.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async titleMetaForSizeToDefaults(_axios: AxiosInstance, x_dp_customer_code: string, title_size_code: string, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TitleSchemeEndpointsApiAxiosParamCreator(configuration).titleMetaForSizeToDefaults(x_dp_customer_code, title_size_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.TS.02 Update title metadata scheme of a specific title size code for customer .
         * @param {TitleMetaDataRestDto} body 
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} title_size_code The code that uniquely identifies the title size.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTitleMetaForSize(_axios: AxiosInstance, body: TitleMetaDataRestDto, x_dp_customer_code: string, title_size_code: string, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TitleSchemeEndpointsApiAxiosParamCreator(configuration).updateTitleMetaForSize(body, x_dp_customer_code, title_size_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TitleSchemeEndpointsApi - object-oriented interface
 * @export
 * @class TitleSchemeEndpointsApi
 * @extends {BaseAPI}
 */
export class TitleSchemeEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.TS.01 Get the title metadata for title size for this current customer.
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} title_size_code The code that uniquely identifies the title size.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TitleSchemeEndpointsApi
     */
     public getTitleMetaForSize(x_dp_customer_code: string, title_size_code: string, x_dp_language?: string|null, options?: any) {
        return TitleSchemeEndpointsApiFp(this.configuration).getTitleMetaForSize(this.axios, x_dp_customer_code, title_size_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.TS.03 Update title metadata scheme for customer to default values for specific title size code.
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} title_size_code The code that uniquely identifies the title size.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TitleSchemeEndpointsApi
     */
     public titleMetaForSizeToDefaults(x_dp_customer_code: string, title_size_code: string, x_dp_language?: string|null, options?: any) {
        return TitleSchemeEndpointsApiFp(this.configuration).titleMetaForSizeToDefaults(this.axios, x_dp_customer_code, title_size_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.TS.02 Update title metadata scheme of a specific title size code for customer .
     * @param {TitleMetaDataRestDto} body 
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} title_size_code The code that uniquely identifies the title size.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TitleSchemeEndpointsApi
     */
     public updateTitleMetaForSize(body: TitleMetaDataRestDto, x_dp_customer_code: string, title_size_code: string, x_dp_language?: string|null, options?: any) {
        return TitleSchemeEndpointsApiFp(this.configuration).updateTitleMetaForSize(this.axios, body, x_dp_customer_code, title_size_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

}
