
import {defineComponent} from "vue"
import {SymbolRestDtoModel} from "@/models/api/pharma-cms-content-block/SymbolRestDtoModel";
import {convertRgbToHex} from "@/helpers/functions/color";
import {MaterialSymbolRestDtoModel} from "@/models/api/pharma-cms-content-block/MaterialSymbolRestDtoModel";

const __default__ = defineComponent({
  name: "PreviewMaterialSymbol",
  props: {
    materialSymbol: {
      type: [MaterialSymbolRestDtoModel, Object],
      required: true,
      default: SymbolRestDtoModel.createWithDefaults()
    }
  },
  data() {
    return {
      rootFontSize: '14px'
    }
  },
  computed: {
    colorDefault(): string {
      return convertRgbToHex(this.materialSymbol.default_state_color.rgb);
    },
    colorHover(): string {
      return convertRgbToHex(this.materialSymbol.hover_state_color.rgb);
    },
    fontFamily(): string {
      return this.materialSymbol.family_code
        .replaceAll('_', ' ')
        .toLowerCase();
    },
    masFill(): number {
      return this.materialSymbol.is_filled ? 1 : 0;
    },
    masGrad(): string {
      return this.materialSymbol.grade_code
        .replaceAll('MIN', '-')
        .replace(/[^\d-]/g, '');
    },
    masOpsz(): string {
      return this.materialSymbol.optical_size_code.replace(/\D/g, '');
    },
    masWght(): string {
      return this.materialSymbol.weight_code.replace(/\D/g, '');
    },
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1259d094": (_ctx.rootFontSize),
  "adb565bc": (_ctx.colorDefault),
  "09aa1349": (_ctx.materialSymbol.default_state_color.opacity),
  "34695157": (_ctx.fontFamily),
  "948fb1cc": (_ctx.materialSymbol.size_in_rem + 'em'),
  "965f2d84": (_ctx.masFill),
  "964fc8d2": (_ctx.masWght),
  "965e03ee": (_ctx.masGrad),
  "9656c87a": (_ctx.masOpsz),
  "6dfcf746": (_ctx.colorHover),
  "2b717404": (_ctx.materialSymbol.hover_state_color.opacity)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__