/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {MaterialSymbolRestDto} from ".";
import {MultilingualMax2000FieldRestDto} from ".";
import {MultilingualMax50FieldRestDto} from ".";
import {SymbolHorizontalPositionEnum} from "@/api/enums/symbol-horizontal-position-enum";
import {SymbolTargetEnum} from "@/api/enums/symbol-target-enum";
import {SymbolVerticalPositionEnum} from "@/api/enums/symbol-vertical-position-enum";

/**
 * @export
 * @interface SymbolRestDto
 */
export interface SymbolRestDto {
    /**
     * 
     * @type {MaterialSymbolRestDto}
     * @memberof SymbolRestDto
     */
    material_symbol: MaterialSymbolRestDto;

    /**
     * The html target for this symbol page ref.
     * @type {SymbolTargetEnum|null}
     * @memberof SymbolRestDto
     */
    symbol_target?: SymbolTargetEnum|null;

    /**
     * 
     * @type {MultilingualMax2000FieldRestDto|null}
     * @memberof SymbolRestDto
     */
    symbol_url?: MultilingualMax2000FieldRestDto|null;

    /**
     * 
     * @type {MultilingualMax50FieldRestDto|null}
     * @memberof SymbolRestDto
     */
    symbol_page_id?: MultilingualMax50FieldRestDto|null;

    /**
     * Determines the horizontal object-position for the symbol.
     * @type {SymbolHorizontalPositionEnum}
     * @memberof SymbolRestDto
     */
    horizontal_position_code: SymbolHorizontalPositionEnum;

    /**
     * Determines the vertical object-position for the symbol.
     * @type {SymbolVerticalPositionEnum}
     * @memberof SymbolRestDto
     */
    vertical_position_code: SymbolVerticalPositionEnum;

}

/**
 * @export
 * @function cloneSymbolRestDtoMetaData
 */
export function cloneSymbolRestDtoMetaData(dto: SymbolRestDto, source: SymbolRestDto): void {
    dto.symbol_target = source.symbol_target;
    dto.horizontal_position_code = source.horizontal_position_code;
    dto.vertical_position_code = source.vertical_position_code;
}
