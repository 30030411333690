
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import {CodeDescriptionRestDto, MaterialSymbolRestDto} from "@/api/pharma-cms-content-block/models";
import PageLinkPicker from "@/components/UI/PageLinkPicker.vue";
import {PageLinkPayload} from "@/models/payload/PageLinkPayload";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {LinkTargetEnum} from "@/models/enum/LinkTargetEnum";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {SymbolContentRestDtoModel} from "@/models/api/pharma-cms-content-block/SymbolContentRestDtoModel";
import {SymbolTargetEnum} from "@/api/enums/symbol-target-enum";
import Subtitle from "@/components/UI/Subtitle.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import PreviewSymbol from "@/components/UI/PreviewSymbol.vue";
import MaterialSymbolInputComponent from "@/components/UI/MaterialSymbolInputComponent.vue";

export default defineComponent({
  name: "CbSymbol",
  extends: AbstractCbCommon,
  components: {
    MaterialSymbolInputComponent,
    PreviewSymbol,
    Subtitle,
    InputSelect,
    PageLinkPicker,
    BaseSpinner
  },
  data: () => ({
    contentBlock: SymbolContentRestDtoModel.createWithDefaults(),
    pageLinkPayload: PageLinkPayload.createWithDefaults()
  }),
  watch: {
    contentBlock() {
      const externalUrl = this.contentBlock.symbol.symbol_url?.nl ?? null;
      const pageId = this.contentBlock.symbol.symbol_page_id?.nl ?? null;
      const target = this.contentBlock.symbol.symbol_target ?? LinkTargetEnum.SELF;

      this.pageLinkPayload = new PageLinkPayload(externalUrl, pageId, target.toString() as LinkTargetEnum);
    },
  },
  computed: {
    enumHorizontalPosition(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.OBJECT_HORIZONTAL_POSITION);
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/symbol', ['create', 'find', 'save']),
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.OBJECT_HORIZONTAL_POSITION,
        EnumClassNameCmsEnum.OBJECT_VERTICAL_POSITION,
      ];
    },
    getDefaultCbModel(): SymbolContentRestDtoModel {
      return SymbolContentRestDtoModel.createWithDefaults();
    },
    onMaterialSymbolUpdate(payload: MaterialSymbolRestDto): void {
      this.contentBlock.symbol.material_symbol = payload;
      this.onChange();
    },
    onPageLinkPickerChanged(payload: PageLinkPayload): void {
      if (payload.type === PageLinkTypeEnum.NONE) {
        this.contentBlock.symbol.symbol_url = undefined;
        this.contentBlock.symbol.symbol_page_id = undefined;
        this.contentBlock.symbol.symbol_target = undefined;
      } else if (payload.type === PageLinkTypeEnum.URL) {
        this.contentBlock.symbol.symbol_url = new MultilingualRestDtoModel(null, null, null, payload.external_url);
        this.contentBlock.symbol.symbol_page_id = undefined;
        this.contentBlock.symbol.symbol_target = payload.target.toString() as SymbolTargetEnum;
      } else if (payload.type === PageLinkTypeEnum.PAGE) {
        this.contentBlock.symbol.symbol_url = undefined;
        this.contentBlock.symbol.symbol_page_id = new MultilingualRestDtoModel(null, null, null, payload.page_id);
        this.contentBlock.symbol.symbol_target = payload.target.toString() as SymbolTargetEnum;
      }

      this.onChange();
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = SymbolContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as SymbolContentRestDtoModel);
      this.contentBlock = cbClone;
    },
  }
});
