import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b3c269a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "material-symbol-preview bg-light bg-opacity-25 w-100 rounded p-3" }
const _hoisted_2 = { class: "material-symbol-preview-container d-flex overflow-hidden justify-content-center align-items-center" }
const _hoisted_3 = { class: "mas lh-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("i", _hoisted_3, _toDisplayString(this.materialSymbol.name_code), 1)
    ])
  ]))
}