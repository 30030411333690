import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row row-layout mb-4" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "row row-title align-items-end mb-4" }
const _hoisted_4 = { class: "col-auto" }
const _hoisted_5 = { class: "col-2" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "row row-content mb-5" }
const _hoisted_8 = { class: "row row-symbol mb-5" }
const _hoisted_9 = { class: "col-4 col-cb-preview" }
const _hoisted_10 = { class: "col-8 col-cb-settings" }
const _hoisted_11 = { class: "row row-position mb-4" }
const _hoisted_12 = { class: "col-auto" }
const _hoisted_13 = { class: "col-auto" }
const _hoisted_14 = { class: "row row-reference" }
const _hoisted_15 = { class: "col" }
const _hoisted_16 = { class: "row row-button mb-5" }
const _hoisted_17 = { class: "col-12 mb-3" }
const _hoisted_18 = {
  key: 0,
  class: "col-12"
}
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-8" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = { class: "col-4" }
const _hoisted_23 = { class: "col-auto" }
const _hoisted_24 = { class: "col-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_Subtitle = _resolveComponent("Subtitle")!
  const _component_AdvancedPanelSwitcher = _resolveComponent("AdvancedPanelSwitcher")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_TitleMetaInputComponent = _resolveComponent("TitleMetaInputComponent")!
  const _component_AdvancedPanel = _resolveComponent("AdvancedPanel")!
  const _component_InputTextEditor = _resolveComponent("InputTextEditor")!
  const _component_PreviewSymbol = _resolveComponent("PreviewSymbol")!
  const _component_MaterialSymbolInputComponent = _resolveComponent("MaterialSymbolInputComponent")!
  const _component_PageLinkPicker = _resolveComponent("PageLinkPicker")!
  const _component_InputCheckboxBoolean = _resolveComponent("InputCheckboxBoolean")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseSpinner, {
      spin: !this.isReady
    }, null, 8, ["spin"]),
    (this.isReady)
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          as: "form",
          novalidate: ""
        }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_InputSelect, {
                  modelValue: this.contentBlock.layout_code,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((this.contentBlock.layout_code) = $event)),
                    this.onChange
                  ],
                  label: _ctx.$t('layout'),
                  name: "layout",
                  selectText: _ctx.$t('selectText'),
                  options: this.enumLayoutCode,
                  errors: errors
                }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "selectText", "options", "errors"])
              ])
            ]),
            _createVNode(_component_Subtitle, {
              title: _ctx.$t('content')
            }, null, 8, ["title"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_AdvancedPanelSwitcher, {
                  class: "mb-3",
                  "is-advanced": this.isAdvancedTitleMeta,
                  errors: errors,
                  onToggleAdvanced: this.toggleAdvancedTitleMeta
                }, null, 8, ["is-advanced", "errors", "onToggleAdvanced"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_InputSelect, {
                  modelValue: this.contentBlock.title_size_code,
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event: any) => ((this.contentBlock.title_size_code) = $event)),
                    this.onChange
                  ],
                  label: _ctx.$t('size'),
                  name: "title_size_code",
                  selectText: _ctx.$t('selectText'),
                  options: this.enumTitleSizeCode,
                  errors: errors
                }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "selectText", "options", "errors"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_InputText, {
                  modelValue: this.contentBlock.title.nl,
                  "onUpdate:modelValue": [
                    _cache[2] || (_cache[2] = ($event: any) => ((this.contentBlock.title.nl) = $event)),
                    this.onChange
                  ],
                  label: _ctx.$t('title'),
                  name: "title",
                  rules: "max:500",
                  errors: errors
                }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "errors"])
              ])
            ]),
            _createVNode(_component_AdvancedPanel, {
              "is-show-when-advanced": true,
              "is-advanced": this.isAdvancedTitleMeta,
              class: "bg-light pt-3 ms-3 mb-3 p-3 pt-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_TitleMetaInputComponent, {
                  modelValue: this.contentBlock.title_meta,
                  errors: errors,
                  "onUpdate:modelValue": _ctx.onTitleMetaUpdate
                }, null, 8, ["modelValue", "errors", "onUpdate:modelValue"])
              ]),
              _: 2
            }, 1032, ["is-advanced"]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_InputTextEditor, {
                modelValue: this.contentBlock.content.nl,
                "onUpdate:modelValue": [
                  _cache[3] || (_cache[3] = ($event: any) => ((this.contentBlock.content.nl) = $event)),
                  _ctx.onChange
                ],
                name: "content",
                class: "col-12",
                errors: errors
              }, null, 8, ["modelValue", "onUpdate:modelValue", "errors"])
            ]),
            _createVNode(_component_Subtitle, {
              title: _ctx.$t('symbol')
            }, null, 8, ["title"]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_PreviewSymbol, {
                  symbol: this.contentBlock.symbol
                }, null, 8, ["symbol"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_MaterialSymbolInputComponent, {
                  modelValue: this.contentBlock.symbol.material_symbol,
                  errors: errors,
                  "onUpdate:modelValue": _ctx.onMaterialSymbolUpdate
                }, null, 8, ["modelValue", "errors", "onUpdate:modelValue"]),
                _createVNode(_component_Subtitle, {
                  title: _ctx.$t('cms.alignment')
                }, null, 8, ["title"]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_InputSelect, {
                      modelValue: this.contentBlock.symbol.horizontal_position_code,
                      "onUpdate:modelValue": [
                        _cache[4] || (_cache[4] = ($event: any) => ((this.contentBlock.symbol.horizontal_position_code) = $event)),
                        this.onChange
                      ],
                      label: _ctx.$t('cms.horizontal'),
                      name: "horizontal_position_code",
                      selectText: _ctx.$t('selectText'),
                      options: this.enumObjectHorizontalPosition,
                      rules: "required",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "selectText", "options", "errors", "onUpdate:modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_InputSelect, {
                      modelValue: this.contentBlock.symbol.vertical_position_code,
                      "onUpdate:modelValue": [
                        _cache[5] || (_cache[5] = ($event: any) => ((this.contentBlock.symbol.vertical_position_code) = $event)),
                        this.onChange
                      ],
                      label: _ctx.$t('cms.vertical'),
                      name: "vertical_position_code",
                      selectText: _ctx.$t('selectText'),
                      options: this.enumVerticalPosition,
                      rules: "required",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "selectText", "options", "errors", "onUpdate:modelValue"])
                  ])
                ]),
                _createVNode(_component_Subtitle, {
                  title: _ctx.$t('reference')
                }, null, 8, ["title"]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_PageLinkPicker, {
                      payload: this.pageLinkSymbolPayload,
                      errors: errors,
                      onChanged: this.onPageLinkPickerSymbolChanged
                    }, null, 8, ["payload", "errors", "onChanged"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_InputCheckboxBoolean, {
                  modelValue: this.useButton,
                  "onUpdate:modelValue": [
                    _cache[6] || (_cache[6] = ($event: any) => ((this.useButton) = $event)),
                    this.onChange
                  ],
                  label: _ctx.$t('useButton'),
                  name: "useButton",
                  errors: errors
                }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "errors"])
              ]),
              (this.useButton)
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createVNode(_component_InputText, {
                          modelValue: this.contentBlock.button.button_text.nl,
                          "onUpdate:modelValue": [
                            _cache[7] || (_cache[7] = ($event: any) => ((this.contentBlock.button.button_text.nl) = $event)),
                            _ctx.onChange
                          ],
                          label: _ctx.$t('buttonText'),
                          placeholder: "buttonText",
                          name: "buttonText",
                          rules: "max:100",
                          errors: errors
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "errors"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _createVNode(_component_InputSelect, {
                          modelValue: this.contentBlock.button_horizontal_position_code,
                          "onUpdate:modelValue": [
                            _cache[8] || (_cache[8] = ($event: any) => ((this.contentBlock.button_horizontal_position_code) = $event)),
                            this.onChange
                          ],
                          label: _ctx.$t('cms.horizontalAlignment'),
                          name: "button_horizontal_position_code",
                          selectText: _ctx.$t('selectText'),
                          options: this.enumObjectHorizontalPosition,
                          errors: errors
                        }, null, 8, ["modelValue", "label", "selectText", "options", "errors", "onUpdate:modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_23, [
                        _createVNode(_component_InputSelect, {
                          modelValue: this.contentBlock.button.button_layout_code,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((this.contentBlock.button.button_layout_code) = $event)),
                          options: this.enumButtonLayoutCode,
                          label: _ctx.$t('layout'),
                          name: "buttonLayout",
                          selectText: _ctx.$t('selectText'),
                          errors: errors
                        }, null, 8, ["modelValue", "options", "label", "selectText", "errors"])
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        _createVNode(_component_PageLinkPicker, {
                          payload: this.pageLinkButtonPayload,
                          "enable-no-link": false,
                          errors: errors,
                          onChanged: this.onPageLinkPickerButtonChanged
                        }, null, 8, ["payload", "errors", "onChanged"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}