
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {default as Modal} from "bootstrap/js/dist/modal";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {AuthContext} from "@/context/AuthContext";
import {mapActions, mapState} from "pinia";
import {useClipboardStore} from "@/stores/ClipboardStore";
import {useToast} from "vue-toastification";
import {TitleSchemeRestService} from "@/services/rest/cms-color-scheme/TitleSchemeRestService";
import {TitleMetaDataRestDtoModel} from "@/models/api/pharma-cms-content-block/TitleMetaDataRestDtoModel";
import {TitleMetaDataRestDto} from "@/api/pharma-cms-color-scheme/models";
import TitleMetaInputComponent from "@/components/UI/TitleMetaInputComponent.vue";

export default defineComponent({
  props: ['mode'],
  name: "BodyScheme",
  components: { TitleMetaInputComponent, BaseSpinner, AlertError2, BaseTitle},
  data: () => ({
    modalColorSchemeTemplates: null as Modal | null,
    toast: useToast(),

    enabledTabValue: 'H1',

    titleMetaH1: TitleMetaDataRestDtoModel.createWithDefaults() as TitleMetaDataRestDto,
    titleMetaH2: TitleMetaDataRestDtoModel.createWithDefaults() as TitleMetaDataRestDto,
    titleMetaH3: TitleMetaDataRestDtoModel.createWithDefaults() as TitleMetaDataRestDto,
    titleMetaH4: TitleMetaDataRestDtoModel.createWithDefaults() as TitleMetaDataRestDto,
    titleMetaH5: TitleMetaDataRestDtoModel.createWithDefaults() as TitleMetaDataRestDto,
    titleMetaH6: TitleMetaDataRestDtoModel.createWithDefaults() as TitleMetaDataRestDto,

    returnRouteObject: {name: 'theme-settings'} as object,
    pageUI: UIStateDto.createWithDefaults()
  }),
  mounted() {
    this.reloadContent();
  },

  computed: {
    ...mapState(useClipboardStore, ["getClipboardColorScheme"]),
    AuthContext() {
      return AuthContext
    },
  },
  methods: {
    ...mapActions(useClipboardStore, ["copyToClipboardColorScheme"]),
    async reloadContent(): Promise<void> {
      this.pageUI.setNotReady();

      try {
        await Promise.all([
          this.titleMetaH1 = await TitleSchemeRestService.getInstance().getTitleScheme('H1'),
          this.titleMetaH2 = await TitleSchemeRestService.getInstance().getTitleScheme('H2'),
          this.titleMetaH3 = await TitleSchemeRestService.getInstance().getTitleScheme('H3'),
          this.titleMetaH4 = await TitleSchemeRestService.getInstance().getTitleScheme('H4'),
          this.titleMetaH5 = await TitleSchemeRestService.getInstance().getTitleScheme('H5'),
          this.titleMetaH6 = await TitleSchemeRestService.getInstance().getTitleScheme('H6'),
        ]);

      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    async submitData(title_size_code: string): Promise<void> {
      this.pageUI.clearError();

      try {
        if (title_size_code === 'H1') {
          TitleSchemeRestService.getInstance().updateTitleScheme(title_size_code, this.titleMetaH1);
        } else if (title_size_code === 'H2') {
          TitleSchemeRestService.getInstance().updateTitleScheme(title_size_code, this.titleMetaH2);
        } else if (title_size_code === 'H3') {
          TitleSchemeRestService.getInstance().updateTitleScheme(title_size_code, this.titleMetaH3);
        } else if (title_size_code === 'H4') {
          TitleSchemeRestService.getInstance().updateTitleScheme(title_size_code, this.titleMetaH4);
        } else if (title_size_code === 'H5') {
          TitleSchemeRestService.getInstance().updateTitleScheme(title_size_code, this.titleMetaH5);
        } else if (title_size_code === 'H6') {
          TitleSchemeRestService.getInstance().updateTitleScheme(title_size_code, this.titleMetaH6);
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        window.scrollTo(0, 0);

        await handleSavedSuccessfully();
        await this.reloadContent();
      }
    },
    isEnabledTab(title_size_code: string): boolean {
      return this.enabledTabValue === title_size_code;
    },
    setEnabledTab(title_size_code: string): void {
      this.enabledTabValue = title_size_code;
    },
    async resetToDefaults(title_size_code: string): Promise<void> {
      this.pageUI.clearError();

      try {
        await TitleSchemeRestService.getInstance().resetTitleSchemeToDefaults(title_size_code);
        await this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        window.scrollTo(0, 0);

        await handleSavedSuccessfully();
        await this.reloadContent();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    },
  }
});
