
import {defineComponent} from "vue";
import {mapActions, mapGetters} from 'vuex';
import {CodeDescriptionRestDto, TitleMetaDataRestDto,} from "@/api/pharma-cms-content-block/models";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import ProductFilterPicker from "@/components/UI/ProductFilterPicker.vue";
import {ProductFilterTypeEnum} from "@/models/enum/ProductFilterTypeEnum";
import {ProductFilterPickerPayload} from "@/models/payload/ProductFilterPickerPayload";
import {ProductFilterOverviewRestDto, ProductFilterRestDto} from "@/api/pharma-cpc-mgmt/models";
import ability from "@/config/ability";
import {generateUUIDv4} from "@/helpers/functions/string";
import {ProductFilterContentRestDtoModel} from "@/models/ProductFilterContentRestDtoModel";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import Subtitle from "@/components/UI/Subtitle.vue";
import {mapActions as mapActionsP} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputText from "@/components/UI/InputText.vue";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";
import AdvancedPanelSwitcher from "@/components/UI/AdvancedPanelSwitcher.vue";
import TitleMetaInputComponent from "@/components/UI/TitleMetaInputComponent.vue";
import AdvancedPanel from "@/components/UI/AdvancedPanel.vue";
import {TitleMetaDataRestDtoModel} from "@/models/api/pharma-cms-content-block/TitleMetaDataRestDtoModel";

export default defineComponent({
  name: "CbProductFilter",
  components: {
    AdvancedPanel,
    TitleMetaInputComponent, AdvancedPanelSwitcher, InputTextEditor, InputText, InputSelect, Subtitle, BaseSpinner, ProductFilterPicker},
  extends: AbstractCbCommon,
  data: () => ({
    contentBlock: ProductFilterContentRestDtoModel.createWithDefaults(),
    currentProductFilterType: ProductFilterTypeEnum.CUSTOM as ProductFilterTypeEnum,
    productFilterPickerPayload: ProductFilterPickerPayload.createWithDefaults(),
    isAdvancedTitleMeta: false,
  }),
  computed: {
    ...mapGetters('cpc_mgmt', ["getComplexProductFilter", "getProductFilterOverview"]),
    namedProductFilterOptions(): CodeDescriptionRestDto[] {
      if (this.canUseNamedProductFilter) {
        const namedProductFilterOptions = [] as CodeDescriptionRestDto[];

        this.getProductFilterOverview.forEach((product_filter: ProductFilterOverviewRestDto) => {
          namedProductFilterOptions.push(
            {code: product_filter.code, description: product_filter.name}
          );
        });

        return namedProductFilterOptions;
      }

      return [];
    },
    productFilter(): ProductFilterRestDto {
      return this.getComplexProductFilter;
    },
    canUseNamedProductFilter(): boolean {
      return ability.can('use-feature', 'NAMED_PRODUCT_FILTER');
    },
    isPageTitle(): boolean {
      return this.cbType === ContentBlockTypesEnum.PAGE_TITLE;
    },
    enumTitleSizeCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.TITLE_SIZE);
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/product_filter', ['create', 'find', 'save']),
    ...mapActions('cpc_mgmt', ["clearComplexProductFilter", "searchComplexProductFilter", "saveComplexProductFilter", "searchProductFilterOverview"]),
    ...mapActionsP(useCustomerStore, ["searchAllBrandsCustomer", "searchAllTrademarksCustomer", "searchAllCategoriesCustomer", "searchProductCategoriesTreeCustomer"]),
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.TITLE_SIZE,
      ];
    },
    getDefaultCbModel(): ProductFilterContentRestDtoModel {
      return ProductFilterContentRestDtoModel.createWithDefaults();
    },
    toggleAdvancedTitleMeta(): void {
      if (!this.isAdvancedTitleMeta && (this.contentBlock.title_meta === null || this.contentBlock.title_meta === undefined)) {
        this.contentBlock.title_meta = TitleMetaDataRestDtoModel.createWithDefaults();
      }
      this.isAdvancedTitleMeta = !this.isAdvancedTitleMeta;
    },
    onTitleMetaUpdate(payload: TitleMetaDataRestDto): void {
      this.contentBlock.title_meta = payload;
      this.onChange();
    },
    async afterReloadContent() {
      if (this.contentBlock.title_meta) {
        this.isAdvancedTitleMeta = true;
      }
      await Promise.all([
        this.searchAllBrandsCustomer(),
        this.searchAllTrademarksCustomer(),
        this.searchAllCategoriesCustomer(),
        this.searchProductCategoriesTreeCustomer(),
      ]);

      if (this.canUseNamedProductFilter) {
        await this.searchProductFilterOverview({is_named: true});
      }
      this.productFilterPickerPayload = new ProductFilterPickerPayload(
        this.contentBlock.product_filter_code ?? null,
        this.namedProductFilterOptions
      );
      this.currentProductFilterType = this.productFilterPickerPayload.type;
      if (this.contentBlock.product_filter_code && this.currentProductFilterType === ProductFilterTypeEnum.CUSTOM) {
        await this.searchComplexProductFilter(this.contentBlock.product_filter_code);
      } else {
        this.clearComplexProductFilter();
      }
    },
    async beforeSubmitData() {
      if (this.currentProductFilterType === ProductFilterTypeEnum.CUSTOM) {
        if (!this.contentBlock.product_filter_code) {
          this.contentBlock.product_filter_code = generateUUIDv4();
        }
        await this.saveComplexProductFilter(this.contentBlock.product_filter_code);
      }
    },
    async onProductFilterPickerChanged(payload: ProductFilterPickerPayload) {
      this.contentBlock.product_filter_code = payload.code;
      this.currentProductFilterType = payload.type;

      this.onChange();
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = ProductFilterContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as ProductFilterContentRestDtoModel);
      this.contentBlock = cbClone;
    },
  }
});
