import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b51b066"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ms-5"
}
const _hoisted_2 = {
  key: 1,
  class: "banner-slot-preview d-flex align-items-center position-relative"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "overlay position-absolute start-0 top-0 w-100 h-100 bg-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!this.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_BaseSpinner)
        ]))
      : _createCommentVNode("", true),
    (this.isReady)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.imageUrls, (imageUrl) => {
            return (_openBlock(), _createElementBlock("div", {
              key: imageUrl,
              class: "image ms-3"
            }, [
              _createElementVNode("img", {
                src: imageUrl,
                alt: "preview",
                style: {"max-inline-size":"100%"}
              }, null, 8, _hoisted_3)
            ]))
          }), 128)),
          (!this.isCurrentSubscription)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}