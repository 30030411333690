import {MaterialSymbolRestDto} from "@/api/pharma-cms-content-block/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {SymbolFamilyEnum} from "@/api/enums/symbol-family-enum";
import {SymbolTargetEnum} from "@/api/enums/symbol-target-enum";
import {SymbolWeightEnum} from "@/api/enums/symbol-weight-enum";
import {SymbolGradeEnum} from "@/api/enums/symbol-grade-enum";
import {SymbolOpticalSizeEnum} from "@/api/enums/symbol-optical-size-enum";

import {ColorRestDtoModel} from "@/models/api/ColorRestDtoModel";

export class MaterialSymbolRestDtoModel implements MaterialSymbolRestDto {
    family_code: SymbolFamilyEnum;
    name_code: string;
    symbol_target?: SymbolTargetEnum | null;
    symbol_url?: MultilingualRestDtoModel | null;
    symbol_page_id?: MultilingualRestDtoModel | null;
    size_in_rem: number;
    default_state_color: ColorRestDtoModel;
    hover_state_color: ColorRestDtoModel;
    is_filled?: boolean | null;
    weight_code: SymbolWeightEnum;
    grade_code: SymbolGradeEnum;
    optical_size_code: SymbolOpticalSizeEnum;

    constructor(family_code: SymbolFamilyEnum, name_code: string, size_in_rem: number, default_state_color: ColorRestDtoModel, hover_state_color: ColorRestDtoModel, weight_code: SymbolWeightEnum, grade_code: SymbolGradeEnum, optical_size_code: SymbolOpticalSizeEnum) {
        this.family_code = family_code;
        this.name_code = name_code;
        this.size_in_rem = size_in_rem;
        this.default_state_color = default_state_color;
        this.hover_state_color = hover_state_color;
        this.weight_code = weight_code;
        this.grade_code = grade_code;
        this.optical_size_code = optical_size_code;
    }

    public static createWithDefaults(): MaterialSymbolRestDtoModel {
        const model = new this(
            SymbolFamilyEnum.MATERIAL_SYMBOLS_OUTLINED,
            'settings',
            2,
            ColorRestDtoModel.createWithDefaults(),
            ColorRestDtoModel.createWithDefaults(),
            SymbolWeightEnum.FW_400,
            SymbolGradeEnum.GRADE_200,
            SymbolOpticalSizeEnum.OPTSZ_48,
        );
        model.is_filled = false;

        return model;
    }
}
