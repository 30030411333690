<template>
  <div
    v-if="visible"
    ref="panel"
    class="floating-panel "
    :style="{ top: `${panelPosition.top}px`, left: `${panelPosition.left}px`, maxHeight: `${maxHeight}px`, overflowY: 'auto'}"
  >
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "FloatingPanel",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Object,
      default: () => ({x: 0, y: 0}),
    },
    maxHeight: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      panelPosition: {
        top: 0,
        left: 0,
      },
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.updatePanelPosition();
      }
    },
  },
  methods: {
    updatePanelPosition() {
      const parentPanel = this.$el.parentElement;
      const targetRect = parentPanel.getBoundingClientRect();
      const panel = this.$el;
      // Get panel width to calculate positioning
      const panelWidth = panel.offsetWidth || 0;
      const viewportWidth = window.innerWidth;

      // Calculate initial position
      let left = this.position.x;
      const top = this.position.y;

      // Adjust if it overflows the viewport
      if (left + panelWidth + targetRect.left > viewportWidth) {
        left = viewportWidth - (panelWidth + targetRect.left);
      }
      this.panelPosition = {top, left};
    },
  },
  mounted() {
    window.addEventListener("resize", this.updatePanelPosition);
    this.updatePanelPosition();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updatePanelPosition);
  },
};
</script>

<style>
.floating-panel {
  position: absolute;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: max-content;
}
</style>
