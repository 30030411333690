
import {defineComponent} from 'vue'
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import {useToast} from "vue-toastification";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {EnumContentRestDtoModel} from "@/models/api/pharma-order-enum/EnumContentRestDtoModel";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";
import {EnumClassNameOrderEnum} from "@/models/enum/EnumClassNameOrderEnum";
import BaseCardBody from "@/components/UI/Bootstrap/Card/BaseCardBody.vue";
import BaseCardHeader from "@/components/UI/Bootstrap/Card/BaseCardHeader.vue";
import BaseCard from "@/components/UI/Bootstrap/Card/BaseCard.vue";
import {mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {I18nContext} from "@/context/I18nContext";
import I18nInputTextEditor from "@/components/UI/I18n/I18nInputTextEditor.vue";
import I18nReadOnlyHtml from "@/components/UI/I18n/I18nReadOnlyHtml.vue";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import I18nInputCheckboxBoolean from "@/components/UI/I18n/I18nInputCheckboxBoolean.vue";
import {MultilingualFieldRestDtoModel} from "@/models/api/pharma-order-enum/MultilingualFieldRestDtoModel";
import {AbilityContext} from "@/context/AbilityContext";
import I18nInputText from "@/components/UI/I18n/I18nInputText.vue";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-mgmt/models";
import {StatusEnum} from "@/api/enums/status-enum";

export default defineComponent({
  name: "DeliveryMethodDetail",
  components: {
    I18nInputText,
    BaseContainer,
    BaseTitle,
    AlertError2,
    MultilingualSwitcher,
    I18nInputTextEditor,
    I18nInputCheckboxBoolean,
    I18nReadOnlyHtml,
    BaseCardBody,
    BaseCardHeader,
    BaseCard,
    BaseSpinner,
  },
  data() {
    return {
      detailModel: EnumContentRestDtoModel.createWithDefaults(),
      deliveryMethodCode: this.$route.params.delivery_method_code as string,
      textTypeCode: this.$route.params.type_code as string,
      deliveryMethodDescription: '',
      textTypeDescription: '',
      statusCode: null as StatusEnum | null,

      overrideFallback : {
        de: false,
        en: false,
        fr: false,
        nl: false,
      },

      activeLanguage: I18nContext.getDefaultApiLanguageKey(),

      toaster: useToast(),
      pageUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapState(useCustomerStore, ["getPharmacyCode"]),
    AbilityContext() {
      return AbilityContext;
    },
    overrideFallbackForCurrentLang() : boolean {
      if (this.activeLanguage === 'nl') {
        return this.overrideFallback.nl;
      }
      if (this.activeLanguage === 'fr') {
        return this.overrideFallback.fr;
      }
      if (this.activeLanguage === 'en') {
        return this.overrideFallback.en;
      }
      if (this.activeLanguage === 'de') {
        return this.overrideFallback.de;
      }
      return false;
    },
    statusOptions(): CodeDescriptionRestDto[] {
      return [
        {
          code: StatusEnum.PUBLISHED,
          description: this.$t('published'),
        },
        {
          code: StatusEnum.NOT_PUBLISHED,
          description: this.$t('notPublished'),
        },
      ];
    },
  },
  methods: {
    async reloadContent(): Promise<void> {
      this.pageUI
        .setNotReady()
        .clearError();

      try {
        this.deliveryMethodDescription = await EnumRestService.getInstance().getOrderEnumDescription(EnumClassNameOrderEnum.DELIVERY_METHOD, this.deliveryMethodCode)
        this.textTypeDescription = await EnumRestService.getInstance().getOrderEnumDescription(EnumClassNameOrderEnum.ENUMERATION_CONTENT_TYPE, this.textTypeCode)

        this.detailModel = await EnumRestService.getInstance().getOrderEnumContentForType(
          this.getPharmacyCode,
          EnumClassNameOrderEnum.DELIVERY_METHOD,
          this.deliveryMethodCode,
          this.textTypeCode,
        );
        if (AbilityContext.isAuthorizedForFeature('MGMT_FALLBACK_CONTENT') && (this.detailModel.fallback_content === undefined || this.detailModel.fallback_content === null )) {
          this.detailModel.fallback_content = MultilingualFieldRestDtoModel.createWithDefaults();
          if (this.textTypeCode === 'INFO_BLOCK_CHECKOUT_CONFIRMATION_POPUP') {
            this.statusCode = StatusEnum.PUBLISHED;
            this.detailModel.field_type_code = 'HTML';
          }
        } else if (this.detailModel.content === null || this.detailModel.content === undefined) {
          this.detailModel.content = MultilingualFieldRestDtoModel.createWithDefaults();
        }
        if (this.detailModel && this.detailModel.status?.code) {
          this.statusCode = this.detailModel.status?.code == StatusEnum.PUBLISHED ? StatusEnum.PUBLISHED : StatusEnum.NOT_PUBLISHED;
        }

        if (this.detailModel.content !== null && this.detailModel.content !== undefined) {
          if (this.detailModel.content.nl !== null) {
            this.overrideFallback.nl = true;
          }
          if (this.detailModel.content.fr !== null) {
            this.overrideFallback.fr = true;
          }
          if (this.detailModel.content.en !== null) {
            this.overrideFallback.en = true;
          }
          if (this.detailModel.content.de !== null) {
            this.overrideFallback.de = true;
          }
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    async submitData(): Promise<void> {
      this.pageUI
        .clearError()
        .setNotReady();

      try {
        if (AbilityContext.isAuthorizedForFeature('MGMT_FALLBACK_CONTENT')) {
          await this.submitFallbackContent();
        } else {
          await this.submitContent();
        }
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.pageUI.setReady();
      }
    },
    async submitContent() : Promise<void> {
      if (this.detailModel.content !== null && this.detailModel.content !== undefined) {
        if (!this.overrideFallback.nl) {
          this.detailModel.content.nl = null;
        }
        if (!this.overrideFallback.fr) {
          this.detailModel.content.fr = null;
        }
        if (!this.overrideFallback.en) {
          this.detailModel.content.en = null;
        }
        if (!this.overrideFallback.de) {
          this.detailModel.content.de = null;
        }
      }

      await EnumRestService.getInstance().updateOrderEnumContentForType(
        this.getPharmacyCode,
        this.detailModel.content,
        EnumClassNameOrderEnum.DELIVERY_METHOD,
        this.deliveryMethodCode,
        this.textTypeCode,
        this.statusCode,
      );
    },
    async submitFallbackContent() : Promise<void> {
      await EnumRestService.getInstance().updateOrderEnumFallbackContentForType(
        this.detailModel.fallback_content,
        EnumClassNameOrderEnum.DELIVERY_METHOD,
        this.deliveryMethodCode,
        this.textTypeCode,
        this.statusCode,
      );
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
})
