
import {defineComponent} from 'vue'
import {mapActions as mapActionsP, mapState} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import InputNumber from "@/components/UI/InputNumber.vue";
import InputText from "@/components/UI/InputText.vue";
import {PimProductsCriteriaModel} from "@/models/criteria/PimProductsCriteriaModel";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {EnumClassNameCpcEnum} from "@/models/enum/EnumClassNameCpcEnum";
import InputSelect from "@/components/UI/InputSelect.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import SelectCategories from "@/components/layouts/catalog/SelectCategories.vue";
import {Config} from "@/models/facade/Config";
import SelectBrands from "@/components/layouts/catalog/SelectBrands.vue";
import InputRadioBoolean from "@/components/UI/InputRadioBoolean.vue";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";
import InputDate from "@/components/UI/InputDate.vue";
import FloatingPanel from "@/components/UI/FloatingPanel.vue";
import {PimProductsRestService} from "@/services/rest/pim-pp2/PimProductsRestService";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

interface CustomCriteria {
  categoryCodeInternal: string | null;
  photographyStatus: boolean | null;
}

export default defineComponent({
  name: "PimProductsOverviewCriteria",
  emits: ["reloadedContent", "submit"],
  components: {
    InputTextEditor,
    FloatingPanel,
    InputDate,
    InputRadioBoolean,
    SelectBrands,
    SelectCategories,
    BaseSpinner, AlertError2, InputSelect, InputText, InputNumber, InputCheckboxBoolean
  },
  data() {
    return {
      catalogCustomerCode: Config.getInstance().getConfig().STARTER_CATALOG_CUSTOMER_CODE as string,
      criteriaUI: UIStateDto.createWithDefaults(),
      customCriteria: {
        categoryCodeInternal: null,
        photographyStatus: null,
      } as CustomCriteria,
      enumApbLegalCategory: [] as CodeDescriptionRestDto[],
      enumApbProductCategory: [] as CodeDescriptionRestDto[],
      enumBelmedisAvailability: [] as CodeDescriptionRestDto[],
      enumCategoryCardinality: [] as CodeDescriptionRestDto[],
      enumFebelcoAvailability: [] as CodeDescriptionRestDto[],
      enumIPrinsUsageType: [] as CodeDescriptionRestDto[],

      userCnkFilter: '' as String,
      isUserCnkFilterPanelVisible: false as boolean,
    }
  },
  mounted() {
    this.reloadContent();
  },
  watch: {
    'productsCriteria.filter_category_code_internal': {
      handler(_new, _old) {
        // This watcher is used to be able to modify the internal category dropdown from the PimProductsOverview via the on onSelectedDpCat and onSelectedIntCat methods
        this.customCriteria.categoryCodeInternal = _new;
      },
      immediate: true
    },
  },
  computed: {
    ...mapState(useCustomerStore, ["getCriteria", "getPaging", "getPimState", "getInternalProductCategories"]),
    productsCriteria(): PimProductsCriteriaModel {
      return this.getCriteria.pim_products;
    },
    productsCriteriaIsChanged(): boolean {
      return this.getPimState.pim_products_criteria_is_changed;
    },
    productsPaging(): PimProductsPagingModel {
      return this.getPaging.pim_products;
    },
    optionsInternalProductCategory(): CodeDescriptionRestDto[] {
      const optionsInternalProductCategory = JSON.parse(JSON.stringify(this.getInternalProductCategories));
      // todo translate
      optionsInternalProductCategory.unshift({code: 'none', description: 'Géén categorie'});

      return optionsInternalProductCategory;
    },
    // todo backend make a boolean of filter_photography_status_code
    // enumPhotographyStatusFilter(): CodeDescriptionRestDto[] {
    //   return this.getEnumByClassname(EnumClassNameCpcEnum.PHOTOGRAPHY_STATUS_FILTER);
    // },
  },
  methods: {
    ...mapActionsP(useCustomerStore, ["clearPimProductsCriteria", "searchAllBrandsCustomer", "searchAllTrademarksCustomer", "searchAllCategoriesCustomer", "searchProductCategoriesTreeCustomer", "searchInternalProductCategories"]),
    clearCriteria(): void {
      this.clearPimProductsCriteria();
      this.customCriteria.categoryCodeInternal = null;
      this.customCriteria.photographyStatus = null;

      this.onPhotographyStatusChanged();
      this.onInternalProductCategoryChanged();

      this.onCriteriaChanged();
    },
    onCriteriaChanged(): void {
      this.getPimState.pim_products_criteria_is_changed = true;
      // console.log('onCriteriaChanged', this.getPimState.pim_products_criteria_is_changed)
    },
    onPhotographyStatusChanged(): void {
      if (this.customCriteria.photographyStatus === true) {
        this.productsCriteria.filter_photography_status_code = 'ONLY_TO_BE_PHOTOGRAPHED';
      } else if (this.customCriteria.photographyStatus === false) {
        this.productsCriteria.filter_photography_status_code = 'ONLY_NOT_TO_BE_PHOTOGRAPHED';
      } else {
        this.productsCriteria.filter_photography_status_code = null;
      }

      this.onCriteriaChanged();
      // console.log('onPhotographyStatusChanged', this.customCriteria.photographyStatus, this.productsCriteria.filter_photography_status_code);
    },
    onInternalProductCategoryChanged(): void {
      if (this.customCriteria.categoryCodeInternal === 'none') {
        this.productsCriteria.filter_category_code_internal = null;
        this.productsCriteria.filter_is_not_in_internal_category = true;
      } else {
        this.productsCriteria.filter_category_code_internal = this.customCriteria.categoryCodeInternal;
        this.productsCriteria.filter_is_not_in_internal_category = null;
      }

      this.onCriteriaChanged();
      // console.log('onInternalProductCategoryChanged', this.productsCriteria.filter_category_code_internal, this.productsCriteria.filter_is_not_in_internal_category)
    },
    setBrandCode(brandCode?: string | null): void {
      this.productsCriteria.filter_brand_code = brandCode;
      this.onCriteriaChanged();
    },
    setCategoryCodeDpManaged(categoryCode?: string | null): void {
      this.productsCriteria.filter_category_code_dp_managed = categoryCode;
      this.onCriteriaChanged();
    },
    closeUserCnkFilterPanel(): void {
      this.isUserCnkFilterPanelVisible = false;
    },
    async saveUserCnkFilterPanel(): Promise<void> {
      try {
        await PimProductsRestService.getInstance().updateUserCnkFilter(this.userCnkFilter);
        this.isUserCnkFilterPanelVisible = false;
      } catch (exceptions) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.criteriaUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.criteriaUI as UIStateDto))
          .catch(exceptions, true);
      }
    },
    async editUserCnkFilter(): Promise<void> {
      console.log('editUserCnkFilter');
      try {
        this.userCnkFilter = await PimProductsRestService.getInstance().getUserCnkFilter();
        console.log('userCnkFilter is ', this.userCnkFilter);
        this.isUserCnkFilterPanelVisible = true;
      } catch (exceptions) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.criteriaUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.criteriaUI as UIStateDto))
          .catch(exceptions, true);
      }
    },
    async reloadContent(): Promise<void> {
      this.criteriaUI
        .setNotReady()
        .clearError();

      try {
        this.enumApbLegalCategory = await EnumRestService.getInstance().getCpcEnumValuesByClassname(EnumClassNameCpcEnum.APB_LEGAL_CATEGORY);
        this.enumApbProductCategory = await EnumRestService.getInstance().getCpcEnumValuesByClassname(EnumClassNameCpcEnum.APB_PRODUCT_CATEGORY);
        this.enumBelmedisAvailability = await EnumRestService.getInstance().getCpcEnumValuesByClassname(EnumClassNameCpcEnum.BELMEDIS_AVAILABILITY);
        this.enumCategoryCardinality = await EnumRestService.getInstance().getCpcEnumValuesByClassname(EnumClassNameCpcEnum.CATEGORY_CARDINALITY);
        this.enumFebelcoAvailability = await EnumRestService.getInstance().getCpcEnumValuesByClassname(EnumClassNameCpcEnum.FEBELCO_AVAILABILITY);
        this.enumIPrinsUsageType = await EnumRestService.getInstance().getCpcEnumValuesByClassname(EnumClassNameCpcEnum.IPRINS_USAGE_TYPE);

        await Promise.all([
          this.searchAllBrandsCustomer(this.catalogCustomerCode),
          this.searchAllTrademarksCustomer(this.catalogCustomerCode),
          this.searchAllCategoriesCustomer(this.catalogCustomerCode),
          this.searchProductCategoriesTreeCustomer(this.catalogCustomerCode),
          this.searchInternalProductCategories()
        ]);

        this.onPhotographyStatusChanged();
        this.onInternalProductCategoryChanged();

        this.getPimState.pim_products_criteria_is_changed = false;
        this.$emit('reloadedContent');
      } catch (exceptions: unknown) {
        ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.criteriaUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.criteriaUI as UIStateDto))
          .catch(exceptions, true);
      } finally {
        this.criteriaUI.setReady();
      }
    },
    submitData(): void {
      if (this.getPimState.pim_products_criteria_is_changed) {
        this.productsPaging.number = 1;
      }

      this.getPimState.pim_products_criteria_is_changed = false;
      this.$emit('submit');
    },
  }
})
