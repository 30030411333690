/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {ContentBlockMetadataRestDto} from ".";
import {MultilingualFieldRestDto} from ".";
import {MultilingualMax500FieldRestDto} from ".";
import {TitleMetaDataRestDto} from ".";
import {StatusEnum} from "@/api/enums/status-enum";
import {TitleSizeEnum} from "@/api/enums/title-size-enum";

/**
 * @export
 * @interface TextWithTitleContentRestDto
 */
export interface TextWithTitleContentRestDto {
    /**
     * The status of this content block.
     * @type {StatusEnum}
     * @memberof TextWithTitleContentRestDto
     */
    status_code: StatusEnum;

    /**
     * The sorting value of this content block.
     * @type {number|null}
     * @memberof TextWithTitleContentRestDto
     */
    sort_weight?: number|null;

    /**
     * Determines the size of the title.
     * @type {TitleSizeEnum|null}
     * @memberof TextWithTitleContentRestDto
     */
    title_size_code?: TitleSizeEnum|null;

    /**
     * 
     * @type {MultilingualMax500FieldRestDto|null}
     * @memberof TextWithTitleContentRestDto
     */
    title?: MultilingualMax500FieldRestDto|null;

    /**
     * 
     * @type {TitleMetaDataRestDto|null}
     * @memberof TextWithTitleContentRestDto
     */
    title_meta?: TitleMetaDataRestDto|null;

    /**
     * 
     * @type {MultilingualFieldRestDto|null}
     * @memberof TextWithTitleContentRestDto
     */
    content?: MultilingualFieldRestDto|null;

    /**
     * 
     * @type {ContentBlockMetadataRestDto|null}
     * @memberof TextWithTitleContentRestDto
     */
    cb_metadata?: ContentBlockMetadataRestDto|null;

}

/**
 * @export
 * @function cloneTextWithTitleContentRestDtoMetaData
 */
export function cloneTextWithTitleContentRestDtoMetaData(dto: TextWithTitleContentRestDto, source: TextWithTitleContentRestDto): void {
    dto.title_size_code = source.title_size_code;
}
