
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {default as Modal} from "bootstrap/js/dist/modal";
import BaseModal from "@/components/UI/Bootstrap/Modal/BaseModal.vue";
import BaseModalButton from "@/components/UI/Bootstrap/Modal/BaseModalButton.vue";
import {ColorSchemeRestDtoModel} from "@/models/api/pharma-cms-color-scheme/ColorSchemeRestDtoModel";
import {CodeDescriptionRestDtoModel} from "@/models/CodeDescriptionRestDtoModel";
import {arrayHasContent} from "@/helpers/functions/arrays";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {ColorSchemeRestService} from "@/services/rest/cms-color-scheme/ColorSchemeRestService";
import InputColor from "@/components/UI/InputColor.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import {AuthContext} from "@/context/AuthContext";
import {mapActions, mapState} from "pinia";
import {useClipboardStore} from "@/stores/ClipboardStore";
import {useToast} from "vue-toastification";

export default defineComponent({
  props: ['mode'],
  name: "ColorScheme",
  components: {InputSelect, InputColor, BaseSpinner, AlertError2, BaseModalButton, BaseModal, BaseTitle},
  data: () => ({
    modalColorSchemeTemplates: null as Modal | null,
    toast: useToast(),

    returnRouteObject: {name: 'theme-settings'} as object,
    isCustomColorScheme: true as boolean,
    hasUnrepresentativeColors: false as boolean,
    activeColorScheme: CodeDescriptionRestDtoModel.createWithDefaults(),
    colorScheme: ColorSchemeRestDtoModel.createWithDefaults(),
    colorSchemeNames: [] as CodeDescriptionRestDtoModel[],
    colorSchemeUI: UIStateDto.createWithDefaults()
  }),
  mounted() {
    this.modalColorSchemeTemplates = new Modal('#modal-color-scheme-templates');
    this.reloadContent();
  },
  unmounted() {
    this.modalColorSchemeTemplates?.dispose();
  },
  computed: {
    ...mapState(useClipboardStore, ["getClipboardColorScheme"]),
    AuthContext() {
      return AuthContext
    },
    hasColorSchemeNames(): boolean {
      return arrayHasContent(this.colorSchemeNames);
    },
    hasClipboardContent(): boolean {
      return !!this.getClipboardColorScheme.colorScheme || !!this.getClipboardColorScheme.activeColorScheme;
    }
  },
  methods: {
    ...mapActions(useClipboardStore, ["copyToClipboardColorScheme"]),
    async reloadContent(): Promise<void> {
      this.colorSchemeUI.setNotReady();

      try {
        await Promise.all([
          this.colorScheme = await ColorSchemeRestService.getInstance().findColorScheme(),
          this.activeColorScheme = await ColorSchemeRestService.getInstance().findActiveColorScheme(),
          this.colorSchemeNames = await ColorSchemeRestService.getInstance().findColorSchemeNames()
        ]);

        if (this.activeColorScheme) {
          this.isCustomColorScheme = false;
        } else {
          this.isCustomColorScheme = true;
          this.activeColorScheme = CodeDescriptionRestDtoModel.createWithDefaults();
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.colorSchemeUI.setReady();
      }
    },
    async submitData(): Promise<void> {
      this.colorSchemeUI.clearError();

      try {
        // console.log('submitting...')
        // console.log("this.isCustomColorScheme", this.isCustomColorScheme)
        if (this.isCustomColorScheme) {
          await ColorSchemeRestService.getInstance().updateColorSchemeCustom(this.colorScheme);
        } else {
          await ColorSchemeRestService.getInstance().updateColorSchemeForTemplate(this.activeColorScheme.code);
        }
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.hasUnrepresentativeColors = false;
        window.scrollTo(0, 0);

        await handleSavedSuccessfully();
        await this.reloadContent();
      }
    },
    async copyToClipBoard(): Promise<void> {
      if (this.isCustomColorScheme) {
        await this.copyToClipboardColorScheme(this.colorScheme, null);
      } else {
        await this.copyToClipboardColorScheme(null, this.activeColorScheme);
      }

      await this.toast.info(this.$t('copiedSuccessfully'));
    },
    async pasteFromClipBoard(): Promise<void> {
      if (this.getClipboardColorScheme.colorScheme) {
        this.colorScheme = this.getClipboardColorScheme.colorScheme;
        this.onCustomColorsChanged();
      } else if (this.getClipboardColorScheme.activeColorScheme) {
        this.activeColorScheme = this.getClipboardColorScheme.activeColorScheme;
        this.onTemplateColorSchemeChanged();
      }

      await this.toast.info(this.$t('pastedSuccessfully'));
    },

    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.colorSchemeUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.colorSchemeUI as UIStateDto))
        .catch(exceptions, true);
    },

    adjustBrightness(hexCode: string, adjustablePercent: number): string {
      let hexCodeArray = [] as number[];
      let calculatedColorArray = [] as string[];

      hexCode = hexCode.replace('#', '');
      if (hexCode.length === 3) {
        hexCode = hexCode[0] + hexCode[0] + hexCode[1] + hexCode[1] + hexCode[2] + hexCode[2];
      }

      const newArray = hexCode.match(/.{1,2}/g) ?? [];
      newArray.forEach((value: string) => {
        hexCodeArray.push(parseInt(value, 16));
      });

      hexCodeArray.forEach((value: number, i: number) => {
        let adjustableLimit;

        if (adjustablePercent < 0) {
          adjustableLimit = value;
        } else {
          adjustableLimit = 255 - value;
        }

        const adjustAmount = Math.ceil(adjustableLimit * adjustablePercent);

        calculatedColorArray[i] = (value + adjustAmount).toString(16);
      });

      return '#' + calculatedColorArray.join('');
    },
    calcColorPrimary(): void {
      this.colorScheme.primary_lighter_color = this.adjustBrightness(this.colorScheme.primary_color, 0.4);
      this.colorScheme.primary_light_color = this.adjustBrightness(this.colorScheme.primary_color, 0.895);
    },
    calcColorSecondary(): void {
      this.colorScheme.secondary_lighter_color = this.adjustBrightness(this.colorScheme.secondary_color, 0.4);
      this.colorScheme.secondary_light_color = this.adjustBrightness(this.colorScheme.secondary_color, 0.895);
    },
    calcColorTertiary(): void {
      this.colorScheme.tertiary_lighter_color = this.adjustBrightness(this.colorScheme.tertiary_color, 0.4);
      this.colorScheme.tertiary_light_color = this.adjustBrightness(this.colorScheme.tertiary_color, 0.895);
    },
    onCustomColorsChanged(): void {
      // console.log('onCustomColorsChanged');
      this.isCustomColorScheme = true;
      this.hasUnrepresentativeColors = false;

      this.activeColorScheme = CodeDescriptionRestDtoModel.createWithDefaults();
    },
    onTemplateColorSchemeChanged(): void {
      // console.log('onTemplateColorSchemeChanged');
      this.isCustomColorScheme = false;
      this.hasUnrepresentativeColors = true;
    },
  }
});
