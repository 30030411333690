import {UpdateEnumContentRestDto} from "@/api/pharma-order-enum/models";
import {MultilingualFieldRestDtoModel} from "@/models/api/pharma-order-enum/MultilingualFieldRestDtoModel";
import {StatusEnum} from "@/api/enums/status-enum";

export class UpdateEnumContentRestDtoModel implements UpdateEnumContentRestDto {
    content: MultilingualFieldRestDtoModel;
    pharmacy_code?: string | null;
    status_code?: StatusEnum | null;

    constructor() {
        this.content = MultilingualFieldRestDtoModel.createWithDefaults();
    }

    public static createWithDefaults(): UpdateEnumContentRestDto {
        return new this()
    }
}
