
import {defineComponent} from 'vue'
import {PagedCatalogProductOverviewResultsRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/PagedCatalogProductOverviewResultsRestDtoModel";
import ProductSelectorLine from "@/components/UI/product/ProductSelectorLine.vue";
import {CatalogProductOverviewRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductOverviewRestDtoModel";
import BasePlaceholderTable from "@/components/UI/Bootstrap/BasePlaceholderTable.vue";

export default defineComponent({
  name: "ProductSelectorList",
  components: {BasePlaceholderTable, ProductSelectorLine},
  emits: ['selectSingleProduct', 'selectProducts'],
  data() {
    return {
      selectedProducts: [] as CatalogProductOverviewRestDtoModel[]
    }
  },
  props: {
    productsOverview: {
      type: [PagedCatalogProductOverviewResultsRestDtoModel, Object],
      required: true
    },
    singleProduct: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    ready: {
      type: Boolean,
      required: true,
    }
  },
  methods: {
    selectProducts() {
      this.$emit('selectProducts', this.selectedProducts);
    },
    selectProduct(product: CatalogProductOverviewRestDtoModel) {
      if (this.singleProduct) {
        this.$emit('selectSingleProduct', product);
      } else {
        this.$emit('selectProducts', [product]);
      }
    },
    changeSelection(selection: {product: CatalogProductOverviewRestDtoModel, selected: boolean}) {
      if (selection.selected) {
        this.selectedProducts.push(selection.product);
      } else {
        const index = this.selectedProducts.indexOf(selection.product);
        this.selectedProducts.splice(index, 1);
      }
    }
  }
})


