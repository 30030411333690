
import {Exception} from "@/api/interfaces";
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import {CmsBannerImageRestDto, CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import {ProductFilterOverviewRestDto, ProductFilterRestDto} from "@/api/pharma-cpc-mgmt/models";
import {StatusEnum} from "@/api/enums/status-enum";
import {determineBannerLinkType} from "@/store/pharma-cms-content-block/middleware";
import {generateUuid} from "@/helpers/functions/string";
import BannerLibraryDetailBannerItem from "@/components/layouts/banners/BannerDetailBannerItem.vue";
import {ProductFilterTypeEnum} from "@/models/enum/ProductFilterTypeEnum";
import {ProductFilterPickerPayload} from "@/models/payload/ProductFilterPickerPayload";
import ProductFilterPicker from "@/components/UI/ProductFilterPicker.vue";
import {PageRefTargetEnum} from "@/api/enums/page-ref-target-enum";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {BannerLinkTypeEnum} from "@/models/enum/BannerLinkTypeEnum";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {mapActions as mapActionsP} from "pinia";
import {useCustomerStore} from "@/stores/CustomerStore";
import {I18nContext} from "@/context/I18nContext";
import {BannerContentV2RestDtoModel} from "@/models/api/pharma-cms-content-block/BannerContentV2RestDtoModel";
import {NormalContentBlockRestService} from "@/services/rest/cms-content-block/NormalContentBlockRestService";
import {AbilityContext} from "@/context/AbilityContext";
import {ContentBlockLibraryRestService} from "@/services/rest/cms-content-block/ContentBlockLibraryRestService";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import Subtitle from "@/components/UI/Subtitle.vue";
import I18nInputTextEditor from "@/components/UI/I18n/I18nInputTextEditor.vue";
import MultilingualSwitcher from "@/components/UI/MultilingualSwitcher.vue";
import I18nPageLinkPicker from "@/components/UI/I18n/I18nPageLinkPicker.vue";
import {I18nPageLinkPayload} from "@/models/payload/I18nPageLinkPayload";
import {BannerContentV2LinkTypeEnum} from "@/api/enums/banner-content-v2-link-type-enum";

export default defineComponent({
  name: "BannerDetail",
  components: {
    I18nPageLinkPicker,
    MultilingualSwitcher,
    I18nInputTextEditor,
    Subtitle,
    BaseTitle,
    ProductFilterPicker,
    BannerLibraryDetailBannerItem
  },
  props: {
    isLibrary: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    cbId: {
      type: String,
      required: false,
    },
    contentLibraryCode: {
      type: String,
      required: false,
    },
    provider: {
      type: String,
      required: false,
      default: 'no-provider',
    },
    returnRoute: {
      type: Object,
      required: false
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      contentBlock: BannerContentV2RestDtoModel.createWithDefaults(),
      isReady: false as boolean,
      error: null,
      exceptions: [] as Exception[],
      savedSuccessfully: false as boolean,
      currentBannerLinkType: BannerLinkTypeEnum.NO_LINK as BannerLinkTypeEnum,
      currentProductFilterType: ProductFilterTypeEnum.CUSTOM as ProductFilterTypeEnum,
      pageLinkPayload: I18nPageLinkPayload.createWithDefaults(),
      productFilterPickerPayload: ProductFilterPickerPayload.createWithDefaults(),
      productFilterTypeEnum: ProductFilterTypeEnum,
      bannerLinkTypeEnum: BannerLinkTypeEnum,
      bannerLinkTypeOptions: [
        {code: BannerLinkTypeEnum.NO_LINK, description: this.$t('noLink')},
        {code: BannerLinkTypeEnum.LINK, description: this.$t('link')},
        {code: BannerLinkTypeEnum.PRODUCT_FILTER, description: this.$t('productFilter')},
      ] as CodeDescriptionRestDto[],
      statusOptions: [
        {code: StatusEnum.PUBLISHED, description: this.$t('published')},
        {code: StatusEnum.NOT_PUBLISHED, description: this.$t('notPublished')}
      ] as CodeDescriptionRestDto[],
      activeLanguage: I18nContext.getDefaultLanguage(),
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cpc_mgmt', ["getComplexProductFilter", "getProductFilterOverview"]),
    cbType(): string {
      if (this.isLibrary) {
        return 'LIB_BANNER';
      } else {
        return 'BANNER';
      }
    },
    helpText(): string {
      return this.$t('descriptionBannerDetail') + '<br><br>' + this.$t('descriptionBannerDetail2');
    },
    libCode(): string | undefined {
      if (this.contentLibraryCode) {
        return this.contentLibraryCode;
      }
      switch (this.provider) {
        case 'dp':
          return 'LIB_DP_BANNERS_VOOR_DP_SLOTS';
        case 'general':
          return 'LIB_DP_BANNERS_ALG';
        case 'farmada':
          return 'LIB_FARMADA_BANNERS';
        case 'no-provider':
          return undefined;
        default:
          throw new Error('Unsupported provider to search ' + this.provider);
      }
    },
    namedProductFilterOptions(): CodeDescriptionRestDto[] {
      if (this.canUseNamedProductFilter) {
        const namedProductFilterOptions = [] as CodeDescriptionRestDto[];

        this.getProductFilterOverview.forEach((product_filter: ProductFilterOverviewRestDto) => {
          namedProductFilterOptions.push(
            {code: product_filter.code, description: product_filter.name}
          );
        });

        return namedProductFilterOptions;
      }

      return [];
    },
    productFilter(): ProductFilterRestDto {
      return this.getComplexProductFilter;
    },
    canUseNamedProductFilter(): boolean {
      return AbilityContext.isAuthorizedForFeature('NAMED_PRODUCT_FILTER');
    },
    returnRouteObject(): object {
      if (this.returnRoute) {
        return this.returnRoute;
      }
      switch (this.provider) {
        case 'dp':
          return {name: 'content-provider-banners-dp'};
        case 'general':
          return {name: 'content-provider-banners-general'};
        case 'farmada':
          return {name: 'content-provider-banners-farmada'};
        case 'no-provider':
          return {name: 'customer-banners-overview'};
        default:
          throw new Error('Unsupported provider to search ' + this.provider);
      }
    },
  },
  methods: {
    availableLanguages(): string[] {
      return I18nContext.getLanguages();
    },
    findBannerImage(language: string, size: string): CmsBannerImageRestDto|null {
        return this.contentBlock?.banner_images?.find(img => {
          return img.language_code === language && img.content_size_code === size;
        }) ?? null;
    },
    contentSizeCodeToHumanReadable(contentSizeCode: string): string {
      switch (contentSizeCode) {
        case 'SIZE_1_1':
          return '<strong>1 / 1</strong> (1296 x 185 px)';
        case 'SIZE_1_2':
          return '<strong>1 / 2</strong> (628 x 185 px)';
      }

      return contentSizeCode;
    },
    addBannerImage(bannerImage: CmsBannerImageRestDto): void {
      this.contentBlock.banner_images.push(bannerImage);
    },
    deleteBannerImage(lang: string | null, size: string): void {
      this.contentBlock.banner_images = this.contentBlock.banner_images.filter(img => {
          return !(img.language_code === lang && img.content_size_code === size);
      });
    },
    clearBannerPeriod(): void {
      this.contentBlock.begin_date = undefined;
      this.contentBlock.end_date = undefined;
    },

    ...mapActions('cpc_mgmt', [
      "clearComplexProductFilter",
      "searchComplexProductFilter",
      "saveComplexProductFilter",
      "searchProductFilterOverview"
    ]),
    ...mapActionsP(useCustomerStore, ["searchAllBrandsCustomer", "searchAllTrademarksCustomer", "searchAllCategoriesCustomer", "searchProductCategoriesTreeCustomer"]),

    onPageLinkPickerChanged(payload: I18nPageLinkPayload) {
      console.log('payload', payload);

      if (payload.type === PageLinkTypeEnum.NONE) {
        this.contentBlock.link_type_code = BannerContentV2LinkTypeEnum.NO_LINK;
      } else if (payload.type === PageLinkTypeEnum.URL) {
        this.contentBlock.link_type_code = BannerContentV2LinkTypeEnum.EXTERNAL_LINK;
        this.contentBlock.ext_link_url = new MultilingualRestDtoModel(payload.external_url.de, payload.external_url.en, payload.external_url.fr, payload.external_url.nl);
        this.contentBlock.internal_page_id = MultilingualRestDtoModel.createWithDefaults();
        this.contentBlock.page_ref_target = payload.target as PageRefTargetEnum;
      } else if (payload.type === PageLinkTypeEnum.PAGE) {
        this.contentBlock.link_type_code = BannerContentV2LinkTypeEnum.INTERNAL_PAGE_REF;
        this.contentBlock.ext_link_url = MultilingualRestDtoModel.createWithDefaults();
        this.contentBlock.internal_page_id = new MultilingualRestDtoModel(payload.page_id.de, payload.page_id.en, payload.page_id.fr, payload.page_id.nl);
        this.contentBlock.page_ref_target = payload.target as PageRefTargetEnum;
      }
    },
    async onProductFilterPickerChanged(payload: ProductFilterPickerPayload) {
      this.contentBlock.product_filter_code = payload.code;
      this.currentProductFilterType = payload.type;
    },
    async parsePageReferences() {
      console.log('parsePageReferences: currentBannerLinkType', this.currentBannerLinkType)
      if (this.currentBannerLinkType === BannerLinkTypeEnum.PRODUCT_FILTER && this.currentProductFilterType === ProductFilterTypeEnum.CUSTOM) {
        if (!this.contentBlock.product_filter_code) {
          this.contentBlock.product_filter_code = generateUuid();
        }
        await this.saveComplexProductFilter(this.contentBlock.product_filter_code);
      }
    },
    async reloadContent() {
      this.isReady = false;
      this.exceptions = [];

      try {
        if (this.mode === 'new') {
          this.currentBannerLinkType = BannerLinkTypeEnum.NO_LINK;
        } else {
          this.contentBlock = await NormalContentBlockRestService.getInstance()
            .getBannerV2(this.cbType, this.cbId);

          this.currentBannerLinkType = determineBannerLinkType(this.contentBlock);
        }

        await Promise.all([
          this.searchAllBrandsCustomer(),
          this.searchAllTrademarksCustomer(),
          this.searchAllCategoriesCustomer(),
          this.searchProductCategoriesTreeCustomer()
        ]);

        this.pageLinkPayload = I18nPageLinkPayload.fromBannerV2(this.contentBlock);

        if (this.canUseNamedProductFilter) {
          await this.searchProductFilterOverview({is_named: true});
        }

        this.productFilterPickerPayload = new ProductFilterPickerPayload(
          this.contentBlock.product_filter_code ?? null,
          this.namedProductFilterOptions
        );
        this.currentProductFilterType = this.productFilterPickerPayload.type;
        if (this.currentBannerLinkType === BannerLinkTypeEnum.PRODUCT_FILTER && this.currentProductFilterType === ProductFilterTypeEnum.CUSTOM) {
          await this.searchComplexProductFilter(this.contentBlock.product_filter_code);
        } else {
          this.clearComplexProductFilter();
        }
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }

      this.isReady = true;
    },
    async submitData() {
      this.exceptions = [];

      try {
        await this.parsePageReferences();

        switch (this.currentBannerLinkType) {
            case BannerLinkTypeEnum.NO_LINK:
              this.contentBlock.link_type_code = BannerContentV2LinkTypeEnum.NO_LINK;
              this.contentBlock.ext_link_url = null;
              this.contentBlock.internal_page_id = null;
              this.contentBlock.product_filter_code = null;
              this.contentBlock.page_ref_target = null;
              break;
            case BannerLinkTypeEnum.LINK:
              this.contentBlock.product_filter_code = null;
              break;
            case BannerLinkTypeEnum.PRODUCT_FILTER:
              this.contentBlock.link_type_code = BannerContentV2LinkTypeEnum.PRODUCT_FILTER;
              this.contentBlock.internal_page_id = null;
              this.contentBlock.page_ref_target = null;
              this.contentBlock.ext_link_url = null;
              break;
        }

        if (this.mode === 'new') {
          if (this.isLibrary) {
            await ContentBlockLibraryRestService.getInstance().createLibBannerV2(this.libCode, this.contentBlock);
          } else {
            await NormalContentBlockRestService.getInstance().createBannerV2(this.cbType, this.contentBlock);
          }
        } else {
          await NormalContentBlockRestService.getInstance().updateBannerV2(this.cbId, this.cbType, this.contentBlock);
        }

        await handleSavedSuccessfully('Banner succesvol opgeslagen');
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
      }
    },
    setExceptions(exceptions: Exception[]) {
      window.scrollTo(0, 0);
      this.exceptions = exceptions;
    }
  }
});
