
import {defineComponent} from "vue";
import BaseContainer from "@/components/UI/Bootstrap/BaseContainer.vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {AbilityContext} from "@/context/AbilityContext";

export default defineComponent({
  name: "PharmaShipping",
  computed: {
    AbilityContext() {
      return AbilityContext
    }
  },
  components: {
    BaseTitle, BaseContainer
  },
  data() {
    return {}
  },
  mounted() {

  },
});
