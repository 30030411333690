
import {defineComponent, PropType} from "vue";
import InputText from "@/components/UI/InputText.vue";
import InputNumber from "@/components/UI/InputNumber.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import ColorPicker from "@/components/UI/ColorPicker.vue";
import Subtitle from "@/components/UI/Subtitle.vue";
import {MaterialSymbolRestDto} from "@/api/pharma-cms-content-block/models";
import {MaterialSymbolRestDtoModel} from "@/models/api/pharma-cms-content-block/MaterialSymbolRestDtoModel";
import {CodeDescriptionRestDto} from "@/api/pharma-cpc-mgmt/models";
import {EnumRestService} from "@/services/rest/enum/EnumRestService";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import {ColorPickerPayload} from "@/models/payload/ColorPickerPayload";
import PreviewMaterialSymbol from "@/components/UI/PreviewMaterialSymbol.vue";

export default defineComponent({
  name: "MaterialSymbolInputComponent",
  components: {
    PreviewMaterialSymbol,
    AlertError2,
    InputText,
    InputNumber,
    InputSelect,
    InputCheckboxBoolean,
    ColorPicker,
    Subtitle,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object as PropType<MaterialSymbolRestDto>,
      required: true,
    },
    showPreview: {
      type: Boolean,
      required: false,
      default: false
    },
    showExternalInfo: {
      type: Boolean,
      required: false,
      default: true
    },
    name: {
      type: String,
      required: false,
      default: 'default'
    },
    title: {
      type: String,
      required: false,
    },
    class: {
      type: String,
      required: false
    },
    errors: {
      type: Object,
    }
  },
  data() {
    return {
      materialSymbol: MaterialSymbolRestDtoModel.createWithDefaults(),
      symbolFamilyOptions: [] as CodeDescriptionRestDto[],
      symbolWeightOptions: [] as CodeDescriptionRestDto[],
      symbolGradeOptions: [] as CodeDescriptionRestDto[],
      symbolOpticalSizeOptions: [] as CodeDescriptionRestDto[],
      pageUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.materialSymbol = this.modelValue;
    this.reloadContent();
  },
  methods: {
    async reloadContent() {
      this.pageUI
        .clearError()
        .setNotReady();
      try {
        this.symbolFamilyOptions = await EnumRestService.getInstance().getCmsEnumValuesByClassname(EnumClassNameCmsEnum.SYMBOL_FAMILY_ENUM);
        this.symbolWeightOptions = await EnumRestService.getInstance().getCmsEnumValuesByClassname(EnumClassNameCmsEnum.SYMBOL_WEIGHT_ENUM);
        this.symbolGradeOptions = await EnumRestService.getInstance().getCmsEnumValuesByClassname(EnumClassNameCmsEnum.SYMBOL_GRADE_ENUM);
        this.symbolOpticalSizeOptions = await EnumRestService.getInstance().getCmsEnumValuesByClassname(EnumClassNameCmsEnum.SYMBOL_OPTICAL_SIZE_ENUM);
      } catch (exceptions: any) {
        this.setExceptions(exceptions);
        window.scrollTo(0, 0);
      } finally {
        this.pageUI.setReady();
      }
    },
    onChangeSymbolName(newValue: any): void {
      this.materialSymbol.name_code = newValue
        .toLowerCase()
        .replaceAll(' ', '_');
      this.onUpdate();
    },
    onChangeColorDefault(payload: ColorPickerPayload): void {
      this.materialSymbol.default_state_color = payload.colorRestDto;
      this.onUpdate();
    },
    onChangeColorHover(payload: ColorPickerPayload): void {
      this.materialSymbol.hover_state_color = payload.colorRestDto;
      this.onUpdate();
    },
    onUpdate() {
      this.$emit("update:modelValue", this.materialSymbol);
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    }
  }
});
