
import {defineComponent} from 'vue'
import {CatalogProductOverviewRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/CatalogProductOverviewRestDtoModel";
import ItemIcons from "@/components/layouts/pim/PimProductsOverviewItemIcons.vue";

export default defineComponent({
  name: "SelectedProduct",
  emits: ['selectionRemoved'],
  components: {ItemIcons},
  props: {
    product: {
      type: [CatalogProductOverviewRestDtoModel, Object],
      required: true,
    },
  },
  methods: {
    deleteSelection() {
      this.$emit('selectionRemoved');
    }
  }
})
