
import {defineComponent} from "vue";
import BaseTitle from "@/components/UI/BaseTitle.vue";
import {default as Modal} from "bootstrap/js/dist/modal";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import {handleSavedSuccessfully} from "@/helpers/toast-helper";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import {AuthContext} from "@/context/AuthContext";
import {mapActions, mapState} from "pinia";
import {useClipboardStore} from "@/stores/ClipboardStore";
import {useToast} from "vue-toastification";
import {BodySchemeRestService} from "@/services/rest/cms-color-scheme/BodySchemeRestService";
import {BodySchemeRestDtoModel} from "@/models/api/pharma-cms-color-scheme/BodySchemeRestDtoModel";
import ColorPicker from "@/components/UI/ColorPicker.vue";
import {ColorPickerPayload} from "@/models/payload/ColorPickerPayload";
import InputNumber from "@/components/UI/InputNumber.vue";

export default defineComponent({
  props: ['mode'],
  name: "BodyScheme",
  components: {InputNumber, ColorPicker, BaseSpinner, AlertError2, BaseTitle},
  data: () => ({
    modalColorSchemeTemplates: null as Modal | null,
    toast: useToast(),

    bodyScheme: BodySchemeRestDtoModel.createWithDefaults() as BodySchemeRestDtoModel,

    returnRouteObject: {name: 'theme-settings'} as object,
    pageUI: UIStateDto.createWithDefaults()
  }),
  mounted() {
    this.reloadContent();
  },

  computed: {
    ...mapState(useClipboardStore, ["getClipboardColorScheme"]),
    AuthContext() {
      return AuthContext
    },
  },
  methods: {
    ...mapActions(useClipboardStore, ["copyToClipboardColorScheme"]),
    onChangeTextColor(payload: ColorPickerPayload): void {
      this.bodyScheme.text_color = payload.colorRestDto;
    },
    onChangeBackgroundColor(payload: ColorPickerPayload): void {
      this.bodyScheme.background_color = payload.colorRestDto;
    },
    async reloadContent(): Promise<void> {
      this.pageUI.setNotReady();

      try {
        await Promise.all([
          this.bodyScheme = await BodySchemeRestService.getInstance().getBodyScheme(),
        ]);

      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.pageUI.setReady();
      }
    },
    async submitData(): Promise<void> {
      this.pageUI.clearError();

      try {
        await BodySchemeRestService.getInstance().updateBodyScheme(this.bodyScheme);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        window.scrollTo(0, 0);

        await handleSavedSuccessfully();
        await this.reloadContent();
      }
    },
    async resetToDefaults(): Promise<void> {
      this.pageUI.clearError();

      try {
        await BodySchemeRestService.getInstance().resetBodySchemeToDefaults();
        await this.reloadContent();
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        window.scrollTo(0, 0);

        await handleSavedSuccessfully();
        await this.reloadContent();
      }
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
        .create()
        .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.pageUI as UIStateDto))
        .catch(exceptions, true);
    },
  }
});
