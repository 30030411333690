import {CatalogProductsCriteriaRestDto} from "@/api/pharma-cpc-product-mgmt/models";

export class CatalogProductsCriteriaModel implements CatalogProductsCriteriaRestDto {
    filter_cnk_code?: number | null;
    filter_active_discount_scheme_id?: string | null;
    filter_category_code?: string | null;
    expect_products_in_subcategory?: boolean | null;
    filter_labo_code?: string | null;
    filter_product_filter_code?: string | null;
    filter_trademark_code?: string | null;
    search_keyword?: string | null;
    filter_is_custom_sort_weight_available?: boolean | null;


    constructor() {
    }

    public static createWithDefaults(): CatalogProductsCriteriaModel {
        const model = new this();

        model.filter_cnk_code = null;
        model.filter_active_discount_scheme_id = null;
        model.filter_category_code = null;
        model.expect_products_in_subcategory = false;
        model.filter_labo_code = null;
        model.filter_product_filter_code = null;
        model.filter_trademark_code = null;
        model.search_keyword = null;
        model.filter_is_custom_sort_weight_available = null;

        return model;
    }

    public copy(): CatalogProductsCriteriaModel {
        const model = new CatalogProductsCriteriaModel();

        model.filter_cnk_code = this.filter_cnk_code;
        model.filter_active_discount_scheme_id = this.filter_active_discount_scheme_id;
        model.filter_category_code = this.filter_category_code;
        model.expect_products_in_subcategory = this.expect_products_in_subcategory;
        model.filter_labo_code = this.filter_labo_code;
        model.filter_product_filter_code = this.filter_product_filter_code;
        model.filter_trademark_code = this.filter_trademark_code;
        model.search_keyword = this.search_keyword;
        model.filter_is_custom_sort_weight_available = this.filter_is_custom_sort_weight_available;

        return model;
    }

    public static createWithCnkOnly(cnk_code?: number | null): CatalogProductsCriteriaModel {
        const model = this.createWithDefaults();

        model.filter_cnk_code = cnk_code;
        model.expect_products_in_subcategory = null;

        return model;
    }
}
