
import {defineComponent, ref} from "vue";
import {mapActions, mapGetters} from 'vuex';
import {CodeDescriptionRestDto} from "@/api/pharma-cms-content-block/models";
import PageLinkPicker from "@/components/UI/PageLinkPicker.vue";
import {PageLinkPayload} from "@/models/payload/PageLinkPayload";
import {PageLinkTypeEnum} from "@/models/enum/PageLinkTypeEnum";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {LinkTargetEnum} from "@/models/enum/LinkTargetEnum";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {ImageTargetEnum} from "@/api/enums/image-target-enum";
import {TextWithImageContentRestDtoModel} from "@/models/api/pharma-cms-content-block/TextWithImageContentRestDtoModel";
import {ButtonTargetEnum} from "@/api/enums/button-target-enum";
import {ButtonRestDtoModel} from "@/models/api/pharma-cms-content-block/ButtonRestDtoModel";
import {ImageRestDtoModel} from "@/models/api/pharma-cms-content-block/ImageRestDtoModel";
import {TextWithImageContentBlockLayoutEnum} from "@/api/enums/text-with-image-content-block-layout-enum";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import Subtitle from "@/components/UI/Subtitle.vue";
import {CmsDocumentRestService} from "@/services/rest/cms-document/CmsDocumentRestService";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputFile from "@/components/UI/InputFile.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import InputText from "@/components/UI/InputText.vue";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";

export default defineComponent({
  name: "CbPharmacyInfoCb",
  emits: ["changed", "hasUnsavedChanges", "exceptions"],
  components: {
    InputTextEditor,
    InputText,
    InputCheckboxBoolean, InputFile, InputSelect, Subtitle, BaseSpinner, PageLinkPicker},
  props: {
    contentBlockPayload: {
      type: TextWithImageContentRestDtoModel,
      required: true,
    },
  },
  data: () => ({
    isReady: false as boolean,
    pageLinkButtonPayload: PageLinkPayload.createWithDefaults(),
    pageLinkImagePayload: PageLinkPayload.createWithDefaults(),
    docType: 'PAGE_IMG' as string,
    useButton: false as boolean,
    removedButton: ButtonRestDtoModel.createWithDefaults(),

    imagePreviewUrl: null as string | null,
  }),
  watch: {
    contentBlock: {
      handler() {
        this.$emit('changed', this.contentBlock);
      },
      deep: true,
    },
    useButton(newVal) {
      if (newVal === true) {
        this.contentBlock.button = this.removedButton;

        const externalUrlButton = this.contentBlock.button?.button_url?.nl ?? null;
        const pageIdButton = this.contentBlock.button?.button_page_id?.nl ?? null;
        const targetButton = this.contentBlock.button?.button_target ?? LinkTargetEnum.SELF;
        this.pageLinkButtonPayload = new PageLinkPayload(externalUrlButton, pageIdButton, targetButton.toString() as LinkTargetEnum);
      } else {
        if (this.contentBlock.button !== undefined) {
          this.removedButton = this.contentBlock.button;
        }
        this.contentBlock.button = undefined
      }
    }
  },
  setup(props) {
    const contentBlock = ref(props.contentBlockPayload) as unknown as TextWithImageContentRestDtoModel;

    return {
      contentBlock,
    };
  },
  mounted() {
    this.reloadContent();
  },
  computed: {
    ...mapGetters('cms_enum', ['getEnumByClassname']),
    enumBorderTypeCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.BORDER_TYPE);
    },
    enumBorderWidthCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.BORDER_WIDTH);
    },
    enumImageWidthCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.IMAGE_WIDTH);
    },
    enumLayoutCode(): CodeDescriptionRestDto[] {
      const enumLayoutCode = [] as CodeDescriptionRestDto[];

      // tmp fix for positioning the image
      this.getEnum(EnumClassNameCmsEnum.TEXT_WITH_IMAGE_LAYOUT).forEach((enumItem: CodeDescriptionRestDto) => {
        if (enumItem.code === TextWithImageContentBlockLayoutEnum.IMAGE_ABOVE_TEXT) {
          enumLayoutCode.push({
            code: enumItem.code,
            description: enumItem.description
          });
        }
      });

      return enumLayoutCode;
    },
    enumTitleSizeCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.TITLE_SIZE);
    },
    existingDocumentKey(): string | null {
      return this.contentBlock.image?.image_key?.nl ?? null;
    },
  },
  methods: {
    ...mapActions('cms_enum', ['findManyEnumByClassname']),
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.BORDER_TYPE,
        EnumClassNameCmsEnum.BORDER_WIDTH,
        EnumClassNameCmsEnum.IMAGE_WIDTH,
        EnumClassNameCmsEnum.TEXT_WITH_IMAGE_LAYOUT,
        EnumClassNameCmsEnum.TITLE_SIZE,
      ];
    },
    getEnum(className: EnumClassNameCmsEnum) {
      return this.getEnumByClassname(className);
    },
    onChange() {
      this.$emit('hasUnsavedChanges');
    },
    onPageLinkPickerButtonChanged(payload: PageLinkPayload) {
      if (this.contentBlock.button) {
        if (payload.type === PageLinkTypeEnum.NONE) {
          this.contentBlock.button.button_url = MultilingualRestDtoModel.createWithDefaults();
          this.contentBlock.button.button_page_id = undefined;
          this.contentBlock.button.button_target = ButtonTargetEnum.SELF;
        } else if (payload.type === PageLinkTypeEnum.URL) {
          this.contentBlock.button.button_url = new MultilingualRestDtoModel(null, null, null, payload.external_url);
          this.contentBlock.button.button_page_id = undefined;
          this.contentBlock.button.button_target = payload.target.toString() as ButtonTargetEnum;
        } else if (payload.type === PageLinkTypeEnum.PAGE) {
          this.contentBlock.button.button_url = MultilingualRestDtoModel.createWithDefaults();
          this.contentBlock.button.button_page_id = new MultilingualRestDtoModel(null, null, null, payload.page_id);
          this.contentBlock.button.button_target = payload.target.toString() as ButtonTargetEnum;
        }
      }

      this.onChange();
    },
    onPageLinkPickerImageChanged(payload: PageLinkPayload) {
      if (this.contentBlock.image) {
        if (payload.type === PageLinkTypeEnum.NONE) {
          this.contentBlock.image.image_url = undefined;
          this.contentBlock.image.image_page_id = undefined;
          this.contentBlock.image.image_target = undefined;
        } else if (payload.type === PageLinkTypeEnum.URL) {
          this.contentBlock.image.image_url = new MultilingualRestDtoModel(null, null, null, payload.external_url);
          this.contentBlock.image.image_page_id = undefined;
          this.contentBlock.image.image_target = payload.target.toString() as ImageTargetEnum;
        } else if (payload.type === PageLinkTypeEnum.PAGE) {
          this.contentBlock.image.image_url = undefined;
          this.contentBlock.image.image_page_id = new MultilingualRestDtoModel(null, null, null, payload.page_id);
          this.contentBlock.image.image_target = payload.target.toString() as ImageTargetEnum;
        }
      }

      this.onChange();
    },
    async reloadContent() {
      this.isReady = false;

      try {
        await this.findManyEnumByClassname(this.determineEnums());

        const externalUrlImage = this.contentBlock.image?.image_url?.nl ?? null;
        const pageIdImage = this.contentBlock.image?.image_page_id?.nl ?? null;
        const targetImage = this.contentBlock.image?.image_target ?? LinkTargetEnum.SELF;
        this.pageLinkImagePayload = new PageLinkPayload(externalUrlImage, pageIdImage, targetImage.toString() as LinkTargetEnum);

        const externalUrlButton = this.contentBlock.button?.button_url?.nl ?? null;
        const pageIdButton = this.contentBlock.button?.button_page_id?.nl ?? null;
        const targetButton = this.contentBlock.button?.button_target ?? LinkTargetEnum.SELF;
        this.pageLinkButtonPayload = new PageLinkPayload(externalUrlButton, pageIdButton, targetButton.toString() as LinkTargetEnum);

        if (this.contentBlock.button) {
          this.useButton = true;
          this.removedButton = this.contentBlock.button;
        }
        if (!this.contentBlock.image) {
          this.contentBlock.image = ImageRestDtoModel.createWithDefaults()
        }

        if (this.existingDocumentKey) {
          CmsDocumentRestService.getInstance()
            .findPreviewUrl(this.existingDocumentKey).then((url: string) => {
            this.imagePreviewUrl = url;
          });
        } else {
          this.imagePreviewUrl = null;
        }
      } catch (exceptions: any) {
        this.$emit('exceptions', exceptions);
      }

      this.isReady = true;
    },
    async setImageKey(key: string | null): Promise<void> {
      if (this.contentBlock.image) {
        this.contentBlock.image.image_key = new MultilingualRestDtoModel(null, null, null, key);
      }

      if (key) {
        this.contentBlock.image_key = new MultilingualRestDtoModel(null, null, null, key);
      } else {
        this.contentBlock.image_key = undefined;
      }

      if (key !== null) {
        this.imagePreviewUrl = await CmsDocumentRestService.getInstance().findPreviewUrl(key);
      } else {
        this.imagePreviewUrl = null;
      }

      this.onChange();
    }
  }
});
