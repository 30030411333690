<template>
  <template v-if="configDev">
    <div class="d-none d-lg-block">
      <the-secondary-menu class="col" style="z-index:1050"></the-secondary-menu>

      <main class="d-flex height-100vh">
        <the-side-bar-menu></the-side-bar-menu>

        <div id="content-container" style="flex: 1;">
          <router-view :key="$route.path"></router-view>
          <div id="messageContainer" class="position-fixed bottom-0 end-0 p-3"></div>
        </div>
      </main>
    </div>

    <div class="d-flex d-lg-none row height-100vh justify-content-center ">
      <div class="col-10 d-flex flex-column justify-content-center align-content-center align-items-center">
        <img id="dp-logo" class="d-inline-block img-fluid w-25 mb-3 " alt="Digital Pharma logo"
             src="@/assets/images/logo.png"/>
        <!-- TODO add translations -->
        <h2 class="text-center">Dit platform is niet beschikbaar voor mobiele toestellen.</h2>
        <p class="text-center mb-0">Gelieve het platform te beheren via je laptop of computer.</p>
      </div>
    </div>
  </template>

  <div v-else
       class="row height-100vh d-flex justify-content-center align-items-center align-content-center text-center">
    <h1>Configuratiefout</h1>
    <h2>{{ this.origin }} - {{ config.environment }}</h2>
  </div>

  <DialogsWrapper />
</template>

<script>
import {config} from '@/helpers/fetch-config';
import {version} from '../package.json';
import {mapActions} from 'vuex';
import TheSecondaryMenu from "@/components/layouts/TheSecondaryMenu";
import TheSideBarMenu from '@/components/layouts/TheSideBarMenu';

export default {
  name: 'App',
  components: {
    TheSideBarMenu,
    TheSecondaryMenu
  },
  data: () => ({
    config,
    appVersion: version,
    origin: window.location.origin
  }),
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    configDev() {
      if (window.location.origin.includes('dp-beheer.app.digital-pharma.be') && config.environment === 'Productie') {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.doAutoLogin();
  },
  methods: {
    ...mapActions(['doAutoLogin']),
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/home');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.row > * {
  padding-left: 6px !important;
}

.height-100vh {
  min-height: 100vh !important;
}

#messageContainer {
  z-index: 999;
}
</style>
