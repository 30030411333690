
import {defineComponent} from 'vue'
import InputNumber from "@/components/UI/InputNumber.vue";
import InputText from "@/components/UI/InputText.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import AlertError2 from "@/components/UI/Bootstrap/Alert/AlertError2.vue";
import SelectBrands from "@/components/layouts/catalog/SelectBrands.vue";
import {CatalogProductsCriteriaModel} from "@/models/criteria/CatalogProductsCriteriaModel";
import {CustomerContext} from "@/context/CustomerContext";
import SelectCategories from "@/components/layouts/catalog/SelectCategories.vue";
import InputCheckboxBoolean from "@/components/UI/InputCheckboxBoolean.vue";
import SelectTrademarks from "@/components/layouts/catalog/SelectTrademarks.vue";

export default defineComponent({
  name: "ProductSelectorCriteria",
  emits: ["update:modelValue"],
  components: {
    SelectTrademarks,
    InputCheckboxBoolean,
    SelectCategories,
    SelectBrands,
    AlertError2,
    InputText,
    InputNumber
  },
  props: {
    modelValue: {
      type: CatalogProductsCriteriaModel,
      required: true,
    },
  },
  data() {
    return {
      catalogCustomerCode: CustomerContext.getCustomerCode(),
      catalogProductsCriteria: CatalogProductsCriteriaModel.createWithDefaults(),
      criteriaChanged: false,
      criteriaUI: UIStateDto.createWithDefaults(),
    }
  },
  mounted() {
    this.catalogProductsCriteria = this.modelValue.copy();
    this.criteriaChanged = false;
    this.criteriaUI.setIsReady(true);
  },
  methods: {
    clearCriteria(): void {
      this.catalogProductsCriteria = CatalogProductsCriteriaModel.createWithDefaults();
      this.onCriteriaChanged();
    },
    onCriteriaChanged(): void {
      this.criteriaChanged = true;
    },
    setLaboCode(laboCode?: string | null): void {
      this.catalogProductsCriteria.filter_labo_code = laboCode;
      this.onCriteriaChanged();
    },
    setTrademarkCode(trademarkCode?: string | null): void {
      this.catalogProductsCriteria.filter_trademark_code = trademarkCode;
      this.onCriteriaChanged();
    },
    setCategoryCodeDpManaged(categoryCode?: string | null): void {
      this.catalogProductsCriteria.filter_category_code = categoryCode;
      this.onCriteriaChanged();
    },
    triggerSearch(): void {
      this.criteriaChanged = false;
      this.$emit('update:modelValue', this.catalogProductsCriteria);
    },
  }
})
