import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mas"
}
const _hoisted_2 = {
  key: 1,
  class: "mas"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (
    this.isReady)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(this.class)
      }, [
        _createElementVNode("button", {
          class: "btn btn-icon btn-sm btn-warning",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (this.$emit('toggleAdvanced')), ["prevent"]))
        }, [
          (this.isAdvanced)
            ? (_openBlock(), _createElementBlock("i", _hoisted_1, _toDisplayString(this.iconIsAdvanced), 1))
            : (_openBlock(), _createElementBlock("i", _hoisted_2, _toDisplayString(this.iconIsNotAdvanced), 1))
        ])
      ], 2))
    : _createCommentVNode("", true)
}