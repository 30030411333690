import {TitleMetaDataRestDto,} from "@/api/pharma-cms-content-block/models";
import {TitleMetaDataRestDto as CmsTitleMetaDataRestDto} from "@/api/pharma-cms-color-scheme/models";
import {FontWeightEnum} from "@/api/enums/font-weight-enum";
import {TitleTextColorCodeEnum} from "@/api/enums/title-text-color-code-enum";
import {TextHorizontalPositionEnum} from "@/api/enums/text-horizontal-position-enum";
import {ColorRestDtoModel} from "@/models/api/ColorRestDtoModel";

export class TitleMetaDataRestDtoModel implements TitleMetaDataRestDto, CmsTitleMetaDataRestDto {
    font_size_in_rem?: number | null;
    font_weight_code?: FontWeightEnum | null;
    text_color?: ColorRestDtoModel | null;
    text_color_code?: TitleTextColorCodeEnum | null;
    text_horizontal_position_code?: TextHorizontalPositionEnum | null;


    constructor() {
    }

    public static createWithDefaults(): TitleMetaDataRestDtoModel {
        return new this();
    }

    public static cloneFrom(source: TitleMetaDataRestDtoModel | null): TitleMetaDataRestDtoModel | null {
        if (source === null) {
            return null;
        }

        const model = new this();
        model.font_size_in_rem = source.font_size_in_rem;
        model.font_weight_code = source.font_weight_code;
        if (source.text_color) {
            model.text_color = ColorRestDtoModel.cloneFrom(source.text_color);
        } else {
            model.text_color = null;
        }
        model.text_color_code = source.text_color_code;
        model.text_horizontal_position_code = source.text_horizontal_position_code;

        return model;
    }

}
