
import {defineComponent} from "vue"
import {SymbolRestDtoModel} from "@/models/api/pharma-cms-content-block/SymbolRestDtoModel";
import {SymbolHorizontalPositionEnum} from "@/api/enums/symbol-horizontal-position-enum";
import {SymbolVerticalPositionEnum} from "@/api/enums/symbol-vertical-position-enum";
import {convertRgbToHex} from "@/helpers/functions/color";

const __default__ = defineComponent({
  name: "PreviewSymbol",
  props: {
    symbol: {
      type: [SymbolRestDtoModel, Object],
      required: true,
      default: SymbolRestDtoModel.createWithDefaults()
    }
  },
  data() {
    return {
      rootFontSize: '14px'
    }
  },
  computed: {
    colorDefault(): string {
      return convertRgbToHex(this.symbol.material_symbol.default_state_color.rgb);
    },
    colorHover(): string {
      return convertRgbToHex(this.symbol.material_symbol.hover_state_color.rgb);
    },
    fontFamily(): string {
      return this.symbol.material_symbol.family_code
        .replaceAll('_', ' ')
        .toLowerCase();
    },
    masFill(): number {
      return this.symbol?.material_symbol.is_filled ? 1 : 0;
    },
    masGrad(): string {
      return this.symbol.material_symbol.grade_code
        .replaceAll('MIN', '-')
        .replace(/[^\d-]/g, '');
    },
    masOpsz(): string {
      return this.symbol.material_symbol.optical_size_code.replace(/\D/g, '');
    },
    masWght(): string {
      return this.symbol.material_symbol.weight_code.replace(/\D/g, '');
    },
    horizClass(): string {
      switch (this.symbol.horizontal_position_code) {
        case SymbolHorizontalPositionEnum.LEFT:
          return 'justify-content-start';
        case SymbolHorizontalPositionEnum.CENTER:
          return 'justify-content-center';
        case SymbolHorizontalPositionEnum.RIGHT:
          return 'justify-content-end';
      }

      return 'justify-content-start';
    },
    vertClass(): string {
      switch (this.symbol.vertical_position_code) {
        case SymbolVerticalPositionEnum.TOP:
          return 'align-items-start';
        case SymbolVerticalPositionEnum.CENTER:
          return 'align-items-center';
        case SymbolVerticalPositionEnum.BOTTOM:
          return 'align-items-end';
      }

      return 'align-items-start';
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1fdddff2": (_ctx.rootFontSize),
  "5b9fd544": (_ctx.colorDefault),
  "175a8dc0": (_ctx.symbol.material_symbol.default_state_color.opacity),
  "14c0f893": (_ctx.fontFamily),
  "3078bac3": (_ctx.symbol.material_symbol.size_in_rem + 'em'),
  "d0ff28fc": (_ctx.masFill),
  "d0efc44a": (_ctx.masWght),
  "d0fdff66": (_ctx.masGrad),
  "d0f6c3f2": (_ctx.masOpsz),
  "ad4da8ce": (_ctx.colorHover),
  "0dc7bf8a": (_ctx.symbol.material_symbol.hover_state_color.opacity)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__