/* tslint:disable */
/* eslint-disable */
/**
 * Pharma-CMS Color Scheme API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { BodySchemeRestDto } from '../models';
/**
 * BodySchemeEndpointsApi - axios parameter creator
 * @export
 */
export const BodySchemeEndpointsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.BS.03 Update body metadata scheme for customer to the default values.
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodySchemeToDefaults: async (x_dp_customer_code: string, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling bodySchemeToDefaults.');
            }
            const localVarPath = `/color_scheme/v1/body_scheme/to_defaults`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.BS.01 Get the body scheme metadata for this current customer.
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBodyScheme: async (x_dp_customer_code: string, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling getBodyScheme.');
            }
            const localVarPath = `/color_scheme/v1/body_scheme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary REST.API.CMS.BS.02 Update body metadata scheme for customer.
         * @param {BodySchemeRestDto} body 
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBodyScheme: async (body: BodySchemeRestDto, x_dp_customer_code: string, x_dp_language?: string|null, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateBodyScheme.');
            }
            // verify required parameter 'x_dp_customer_code' is not null or undefined
            if (x_dp_customer_code === null || x_dp_customer_code === undefined) {
                throw new RequiredError('x_dp_customer_code','Required parameter x_dp_customer_code was null or undefined when calling updateBodyScheme.');
            }
            const localVarPath = `/color_scheme/v1/body_scheme`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required

            if (x_dp_customer_code !== undefined && x_dp_customer_code !== null) {
                localVarHeaderParameter['x-dp-customer-code'] = String(x_dp_customer_code);
            }

            if (x_dp_language !== undefined && x_dp_language !== null) {
                localVarHeaderParameter['x-dp-language'] = String(x_dp_language);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BodySchemeEndpointsApi - functional programming interface
 * @export
 */
export const BodySchemeEndpointsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary REST.API.CMS.BS.03 Update body metadata scheme for customer to the default values.
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bodySchemeToDefaults(_axios: AxiosInstance, x_dp_customer_code: string, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BodySchemeEndpointsApiAxiosParamCreator(configuration).bodySchemeToDefaults(x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.BS.01 Get the body scheme metadata for this current customer.
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBodyScheme(_axios: AxiosInstance, x_dp_customer_code: string, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BodySchemeRestDto>> {
            const localVarAxiosArgs = await BodySchemeEndpointsApiAxiosParamCreator(configuration).getBodyScheme(x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary REST.API.CMS.BS.02 Update body metadata scheme for customer.
         * @param {BodySchemeRestDto} body 
         * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
         * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBodyScheme(_axios: AxiosInstance, body: BodySchemeRestDto, x_dp_customer_code: string, x_dp_language?: string|null, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await BodySchemeEndpointsApiAxiosParamCreator(configuration).updateBodyScheme(body, x_dp_customer_code, x_dp_language, options);
            return (axios: AxiosInstance = _axios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BodySchemeEndpointsApi - object-oriented interface
 * @export
 * @class BodySchemeEndpointsApi
 * @extends {BaseAPI}
 */
export class BodySchemeEndpointsApi extends BaseAPI {
    /**
     * 
     * @summary REST.API.CMS.BS.03 Update body metadata scheme for customer to the default values.
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BodySchemeEndpointsApi
     */
     public bodySchemeToDefaults(x_dp_customer_code: string, x_dp_language?: string|null, options?: any) {
        return BodySchemeEndpointsApiFp(this.configuration).bodySchemeToDefaults(this.axios, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.BS.01 Get the body scheme metadata for this current customer.
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BodySchemeEndpointsApi
     */
     public getBodyScheme(x_dp_customer_code: string, x_dp_language?: string|null, options?: any) {
        return BodySchemeEndpointsApiFp(this.configuration).getBodyScheme(this.axios, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary REST.API.CMS.BS.02 Update body metadata scheme for customer.
     * @param {BodySchemeRestDto} body 
     * @param {string} x_dp_customer_code The unique identification of the Customer. If not provided, the request is handled in Digital-Pharma context.
     * @param {string} [x_dp_language] Indicates in which language the product information is expected. (Fallback to default language for customer if not provided.)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BodySchemeEndpointsApi
     */
     public updateBodyScheme(body: BodySchemeRestDto, x_dp_customer_code: string, x_dp_language?: string|null, options?: any) {
        return BodySchemeEndpointsApiFp(this.configuration).updateBodyScheme(this.axios, body, x_dp_customer_code, x_dp_language, options).then((request) => request(this.axios, this.basePath));
    }

}
