import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dece5da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = {
  key: 0,
  class: "col-auto d-flex align-items-center flex-row"
}
const _hoisted_5 = { class: "current-price fw-600 pe-2" }
const _hoisted_6 = { class: "old-price text-decoration-line-through" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputCheckboxBoolean = _resolveComponent("InputCheckboxBoolean")!
  const _component_ItemIcons = _resolveComponent("ItemIcons")!

  return (_openBlock(), _createElementBlock("tr", null, [
    (!_ctx.singleProduct)
      ? (_openBlock(), _createElementBlock("td", _hoisted_1, [
          _createVNode(_component_InputCheckboxBoolean, {
            modelValue: _ctx.selected,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
            name: 'selectProduct' + _ctx.product.product_code,
            errors: _ctx.errors,
            onChange: _ctx.checkboxSelected
          }, null, 8, ["modelValue", "name", "errors", "onChange"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("td", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectProduct && _ctx.selectProduct(...args)))
    }, [
      _createElementVNode("div", null, [
        _createElementVNode("img", {
          src: _ctx.product.main_image_url,
          alt: _ctx.product.product_info.name,
          style: {"width":"50px","height":"50px"}
        }, null, 8, _hoisted_2)
      ])
    ]),
    _createElementVNode("td", {
      class: "icons",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.selectProduct && _ctx.selectProduct(...args)))
    }, [
      _createVNode(_component_ItemIcons, { product: _ctx.product }, null, 8, ["product"])
    ]),
    _createElementVNode("td", {
      class: "cnk",
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.selectProduct && _ctx.selectProduct(...args)))
    }, _toDisplayString(_ctx.product.product_info.cnk_code), 1),
    _createElementVNode("td", {
      class: "name",
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.selectProduct && _ctx.selectProduct(...args)))
    }, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.product.product_info.name), 1)
    ]),
    _createElementVNode("td", {
      class: "price",
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.selectProduct && _ctx.selectProduct(...args)))
    }, [
      ((_ctx.product.product_price?.discount_percentage ?? 0 > 0) || (_ctx.product.product_price?.discount_value_with_vat ?? 0 > 0))
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, " € " + _toDisplayString(_ctx.product.product_price.sale_price_with_vat), 1),
            _createElementVNode("div", _hoisted_6, " € " + _toDisplayString(_ctx.product.product_price.standard_price_with_vat), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, " € " + _toDisplayString(_ctx.product.product_price.sale_price_with_vat), 1))
    ]),
    _createElementVNode("td", {
      class: "discount",
      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.selectProduct && _ctx.selectProduct(...args)))
    }, [
      _createElementVNode("div", {
        innerHTML: _ctx.product.discount?.label?.content ?? ''
      }, null, 8, _hoisted_8)
    ])
  ]))
}