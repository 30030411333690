
import {defineComponent} from "vue";
import {mapActions} from 'vuex';
import {TextWithTitleContentRestDtoModel} from "@/models/api/pharma-cms-content-block/TextWithTitleContentRestDtoModel";
import {CodeDescriptionRestDto, TitleMetaDataRestDto,} from "@/api/pharma-cms-content-block/models";
import AbstractCbCommon from "@/components/layouts/content-pages/wysiwyg/structures/AbstractCbCommon.vue";
import {EnumClassNameCmsEnum} from "@/models/enum/EnumClassNameCmsEnum";
import {ContentBlockTypesEnum} from "@/helpers/functions/enum";
import {CopyCpAbstractPayload} from "@/models/payload/CopyCpAbstractPayload";
import BaseSpinner from "@/components/UI/Bootstrap/BaseSpinner.vue";
import InputSelect from "@/components/UI/InputSelect.vue";
import InputText from "@/components/UI/InputText.vue";
import InputTextEditor from "@/components/UI/InputTextEditor.vue";
import AdvancedPanelSwitcher from "@/components/UI/AdvancedPanelSwitcher.vue";
import AdvancedPanel from "@/components/UI/AdvancedPanel.vue";
import TitleMetaInputComponent from "@/components/UI/TitleMetaInputComponent.vue";
import {TitleMetaDataRestDtoModel} from "@/models/api/pharma-cms-content-block/TitleMetaDataRestDtoModel";

export default defineComponent({
  name: "CbTextWithTitle",
  components: {TitleMetaInputComponent, AdvancedPanel, AdvancedPanelSwitcher, InputTextEditor, InputText, InputSelect, BaseSpinner},
  extends: AbstractCbCommon,
  data: () => ({
    contentBlock: TextWithTitleContentRestDtoModel.createWithDefaults(),
    isAdvancedTitleMeta: false,
  }),
  watch: {
    contentBlock() {
      this.onCopyCpAbstractChanged();
    },
  },
  computed: {
    enumTitleSizeCode(): CodeDescriptionRestDto[] {
      return this.getEnum(EnumClassNameCmsEnum.TITLE_SIZE);
    },
    isPageTitle(): boolean {
      return this.cbType === ContentBlockTypesEnum.PAGE_TITLE;
    },
  },
  methods: {
    ...mapActions('cms_content_block/normal_content_block/text_with_title', [
      'create', 'find', 'save',
    ]),
    determineEnums(): EnumClassNameCmsEnum[] {
      return [
        EnumClassNameCmsEnum.TITLE_SIZE,
      ];
    },
    getDefaultCbModel(): TextWithTitleContentRestDtoModel {
      return TextWithTitleContentRestDtoModel.createWithDefaults();
    },
    onCopyCpAbstractChanged(): void {
      const title = this.contentBlock.title?.nl ?? null;
      const content = this.contentBlock.content?.nl ?? null;
      this.copyCpAbstractPayload = new CopyCpAbstractPayload(content, null, title);

      this.$emit('abstractContentChanged', this.copyCpAbstractPayload);
    },
    toggleAdvancedTitleMeta(): void {
      if (!this.isAdvancedTitleMeta && (this.contentBlock.title_meta === null || this.contentBlock.title_meta === undefined)) {
        this.contentBlock.title_meta = TitleMetaDataRestDtoModel.createWithDefaults();
      }
      this.isAdvancedTitleMeta = !this.isAdvancedTitleMeta;
    },
    onTitleMetaUpdate(payload: TitleMetaDataRestDto): void {
      this.contentBlock.title_meta = payload;
      this.onChange();
    },
    async afterReloadContent(): Promise<void> {
      if (this.contentBlock.title_meta) {
        this.isAdvancedTitleMeta = true;
      }
    },
    async beforeSubmitData(): Promise<void> {
      if (this.contentBlock !== null && this.contentBlock.title_meta !== null && this.contentBlock.title_meta !== undefined
        && this.contentBlock.title_meta.font_size_in_rem === null
        && this.contentBlock.title_meta.font_weight_code === null
        && this.contentBlock.title_meta.text_horizontal_position_code === null
        && this.contentBlock.title_meta.text_color_code === null
        && this.contentBlock.title_meta.text_color === null) {
        this.contentBlock.title_meta = null;
      }
    },
    pasteCbMetadata(): void {
      // When calling clone method directly on object, method is not found. So this is a hacky workaround
      const cbClone = TextWithTitleContentRestDtoModel.cloneFrom(this.contentBlock);

      cbClone.cloneMetadataFrom(this.getClipboardContentBlock(this.cbType) as TextWithTitleContentRestDtoModel);
      this.contentBlock = cbClone;
    },
  }
});
