
import {defineComponent, PropType} from 'vue'
import {CatalogProductOverviewRestDto} from "@/api/pharma-cpc-product-mgmt/models";
import {CatalogProductsRestService} from "@/services/rest/cpc-mgmt/CatalogProductsRestService";
import {PagedCatalogProductOverviewResultsRestDtoModel} from "@/models/api/pharma-cpc-product-mgmt/PagedCatalogProductOverviewResultsRestDtoModel";
import {CatalogProductsCriteriaModel} from "@/models/criteria/CatalogProductsCriteriaModel";
import {PimProductsPagingModel} from "@/models/paging/PimProductsPagingModel";
import InputText from "@/components/UI/InputText.vue";
import FloatingPanel from "@/components/UI/FloatingPanel.vue";
import ProductSelectorList from "@/components/UI/product/ProductSelectorList.vue";
import ProductSelectorCriteria from "@/components/UI/product/ProductSelectorCriteria.vue";
import {UIStateDto} from "@/dtos/UIStateDto";
import {ErrorHandlerQueue} from "@/error/ErrorHandlerQueue";
import {DpExceptionsErrorHandler} from "@/error/handlers/DpExceptionsErrorHandler";
import {AxiosErrorHandler} from "@/error/handlers/AxiosErrorHandler";
import SelectedProduct from "@/components/UI/product/SelectedProduct.vue";

export default defineComponent({
  name: "ProductSelector",
  components: {SelectedProduct, ProductSelectorCriteria, ProductSelectorList, FloatingPanel, InputText},
  data() {
    return {
      catalogProducts: PagedCatalogProductOverviewResultsRestDtoModel.createWithDefaults(),
      catalogProductsCriteria: CatalogProductsCriteriaModel.createWithDefaults(),
      pimProductsPagingModel: PimProductsPagingModel.createWithDefaults(),
      panelKey: 0,
      isSelecting: false as boolean,
      advancedSearch: false as boolean,
      selectorUI: UIStateDto.createWithDefaults(),
      selectedProduct: null as CatalogProductOverviewRestDto | null,
      selectedProducts: [] as CatalogProductOverviewRestDto[],
      isSearching: false,
      timeout: null as number | null,
    };
  },
  props: {
    singleProduct: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object as PropType<CatalogProductOverviewRestDto>,
      required: false,
    },
    products: {
      type: Array as PropType<CatalogProductOverviewRestDto[]>,
      required: false,
    },
    errors: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.selectedProduct = this.product ?? null;
    this.selectedProducts = this.products ?? [];
    this.selectorUI.setIsReady(true);
  },
  emits: ['selectProducts'],
  methods: {
    async reloadContent(): Promise<void> {
      this.selectorUI
          .setNotReady()
          .clearError();
      try {
        this.catalogProducts = await CatalogProductsRestService.getInstance()
            .findCatalogProducts(this.catalogProductsCriteria, this.pimProductsPagingModel);
      } catch (exceptions: unknown) {
        this.setExceptions(exceptions);
      } finally {
        this.selectorUI.setReady();
      }
    },
    selectProducts(products: CatalogProductOverviewRestDto[]): void {
      this.selectedProducts = products;
      this.$emit('selectProducts', products);
      this.isSelecting = false;
    },
    openAdvancedSearch(): void {
      this.advancedSearch = true;
      this.panelKey++;
    },
    selectSingleProduct(product: CatalogProductOverviewRestDto): void {
      this.selectedProduct = product;
      this.$emit('selectProducts', [product]);
      this.isSelecting = false;
    },
    removeSelection(): void {
      this.selectedProduct = null;
      this.$emit('selectProducts', []);
    },
    async searchProductsWithKeyword(): Promise<void> {
      if (this.catalogProductsCriteria.search_keyword !== '') {
        this.isSearching = true;
        this.pimProductsPagingModel.number = 1;
        await this.reloadContent();
        this.isSelecting = true;
        this.isSearching = false;
      }
    },
    handleKeyWordInput() {
      // Do not start a new search if we are still searching
      if (!this.isSearching) {
        // Wait at least 500ms after the last keystroke before searching.  There was input, so we cancel the previous timeout (if any)
        // and start counting again.
        if (this.timeout !== null) {
          clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => {
          this.searchProductsWithKeyword();
          // Set the timeout to null, as there is nothing to clear anymore.
          this.timeout = null;
        }, 500);
      }
    },
    async doAdvancedSearch(criteria: CatalogProductsCriteriaModel): Promise<void> {
      this.isSearching = true;
      this.catalogProductsCriteria = criteria;
      this.pimProductsPagingModel.number = 1;
      await this.reloadContent();
      this.isSelecting = true;
      this.isSearching = false;
    },
    setExceptions(exceptions: unknown): void {
      ErrorHandlerQueue
          .create()
          .add(DpExceptionsErrorHandler.createWithDefaultUIStateBehavior(this.selectorUI as UIStateDto))
          .add(AxiosErrorHandler.createWithDefaultUIStateBehavior(this.selectorUI as UIStateDto))
          .catch(exceptions, true);
    },
    closePanel(): void {
      this.isSelecting = false;
    }
  },
})
