import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-473cd5af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fs-5 fw-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["select-banners-to-subscribe d-flex flex-column align-items-center bg-light rounded", {'active': _ctx.isActive}])
  }, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.setSubscribeToSlotId && _ctx.setSubscribeToSlotId(...args)), ["prevent"])),
      class: _normalizeClass(["btn", _ctx.isActive ? 'btn-primary' : 'btn-outline-primary'])
    }, _toDisplayString(_ctx.isActive ? _ctx.$t('selected') : _ctx.$t('select')), 3),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.description), 1)
  ], 2))
}