import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-4" }
const _hoisted_3 = { class: "row row-title align-items-end" }
const _hoisted_4 = { class: "col-auto" }
const _hoisted_5 = { class: "col-2" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "row row-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_AdvancedPanelSwitcher = _resolveComponent("AdvancedPanelSwitcher")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_TitleMetaInputComponent = _resolveComponent("TitleMetaInputComponent")!
  const _component_AdvancedPanel = _resolveComponent("AdvancedPanel")!
  const _component_InputTextEditor = _resolveComponent("InputTextEditor")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseSpinner, {
      spin: !_ctx.isReady
    }, null, 8, ["spin"]),
    (_ctx.isReady)
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          as: "form",
          onSubmit: _ctx.submitData,
          novalidate: ""
        }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_AdvancedPanelSwitcher, {
                      class: "mb-3",
                      "is-advanced": this.isAdvancedTitleMeta,
                      errors: errors,
                      onToggleAdvanced: this.toggleAdvancedTitleMeta
                    }, null, 8, ["is-advanced", "errors", "onToggleAdvanced"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_InputSelect, {
                      modelValue: this.contentBlock.title_size_code,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((this.contentBlock.title_size_code) = $event)),
                        _ctx.onChange
                      ],
                      label: _ctx.$t('size'),
                      name: "size",
                      selectText: _ctx.$t('selectText'),
                      options: _ctx.enumTitleSizeCode,
                      errors: errors
                    }, null, 8, ["modelValue", "label", "selectText", "options", "errors", "onUpdate:modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_InputText, {
                      modelValue: this.contentBlock.title.nl,
                      "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event: any) => ((this.contentBlock.title.nl) = $event)),
                        _ctx.onChange
                      ],
                      label: _ctx.$t('title'),
                      name: "title",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"])
                  ])
                ]),
                _createVNode(_component_AdvancedPanel, {
                  "is-show-when-advanced": true,
                  "is-advanced": this.isAdvancedTitleMeta,
                  class: "bg-light pt-3 ms-3 mb-3 p-3 pt-4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_TitleMetaInputComponent, {
                      modelValue: this.contentBlock.title_meta,
                      errors: errors,
                      "onUpdate:modelValue": _ctx.onTitleMetaUpdate
                    }, null, 8, ["modelValue", "errors", "onUpdate:modelValue"])
                  ]),
                  _: 2
                }, 1032, ["is-advanced"]),
                _createElementVNode("div", _hoisted_7, [
                  (!_ctx.isPageTitle)
                    ? (_openBlock(), _createBlock(_component_InputTextEditor, {
                        key: 0,
                        modelValue: this.contentBlock.content.nl,
                        "onUpdate:modelValue": [
                          _cache[2] || (_cache[2] = ($event: any) => ((this.contentBlock.content.nl) = $event)),
                          _ctx.onChange
                        ],
                        class: "col-12",
                        label: _ctx.$t('content'),
                        name: "content",
                        errors: errors
                      }, null, 8, ["modelValue", "label", "errors", "onUpdate:modelValue"]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"]))
      : _createCommentVNode("", true)
  ], 64))
}