import {RestService} from "@/services/rest/RestService";
import {ColorSchemeEndpointsApi} from "@/api/pharma-cms-color-scheme";
import {CustomerContext} from "@/context/CustomerContext";
import {CodeDescriptionRestDto} from "@/api/pharma-cms-color-scheme/models";
import {ColorSchemeRestDtoModel} from "@/models/api/pharma-cms-color-scheme/ColorSchemeRestDtoModel";

export class ColorSchemeRestService extends RestService {

    private apiService: ColorSchemeEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new ColorSchemeEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async findActiveColorScheme(): Promise<CodeDescriptionRestDto> {
        const response = await this.apiService.getColorSchemeName();

        return response.data;
    }

    public async findColorScheme(): Promise<ColorSchemeRestDtoModel> {
        const response = await this.apiService.getColorScheme();

        return response.data;
    }

    public async findColorSchemeNames(): Promise<CodeDescriptionRestDto[]> {
        const response = await this.apiService.getTemplateColorSchemeNames();

        return response.data;
    }

    public async updateColorSchemeCustom(color_scheme: ColorSchemeRestDtoModel): Promise<void> {
        await this.apiService.updateColorScheme(color_scheme, CustomerContext.getCustomerCode());
    }

    public async updateColorSchemeForTemplate(color_scheme_code: string): Promise<void> {
        await this.apiService.updateColorSchemeForTemplate(CustomerContext.getCustomerCode(), color_scheme_code);
    }
}
