import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "delivery-method row align-items-start mb-2" }
const _hoisted_2 = { class: "col item-title" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "text-type row align-items-start mb-2" }
const _hoisted_5 = { class: "col item-title" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "fs-5 mb-0" }
const _hoisted_8 = { class: "row mt-3" }
const _hoisted_9 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_10 = {
  key: 0,
  class: "row d-flex justify-content-start align-items-center"
}
const _hoisted_11 = { class: "fs-5 mb-0" }
const _hoisted_12 = { class: "row mt-4" }
const _hoisted_13 = {
  key: 0,
  class: "col-auto"
}
const _hoisted_14 = { class: "row bottom" }
const _hoisted_15 = { class: "col-12" }
const _hoisted_16 = { class: "d-inline-block pt-4 border-top border-2 border-light" }
const _hoisted_17 = {
  type: "submit",
  class: "btn btn-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTitle = _resolveComponent("BaseTitle")!
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_MultilingualSwitcher = _resolveComponent("MultilingualSwitcher")!
  const _component_BaseCardBody = _resolveComponent("BaseCardBody")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_BaseCardHeader = _resolveComponent("BaseCardHeader")!
  const _component_i18n_read_only_html = _resolveComponent("i18n-read-only-html")!
  const _component_i18n_input_text_editor = _resolveComponent("i18n-input-text-editor")!
  const _component_i18n_input_text = _resolveComponent("i18n-input-text")!
  const _component_input_select = _resolveComponent("input-select")!
  const _component_i18n_input_checkbox_boolean = _resolveComponent("i18n-input-checkbox-boolean")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_dp_form = _resolveComponent("dp-form")!
  const _component_base_container = _resolveComponent("base-container")!

  return (_openBlock(), _createBlock(_component_base_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_BaseTitle, {
        title: _ctx.$t('deliveryMethodText'),
        mb: 5,
        "show-refresh-button": "",
        onRefresh: this.reloadContent
      }, null, 8, ["title", "onRefresh"]),
      _createVNode(_component_AlertError2, {
        error: this.pageUI.getError()
      }, null, 8, ["error"]),
      _createVNode(_component_BaseSpinner, {
        spin: !this.pageUI.getIsReady()
      }, null, 8, ["spin"]),
      _createElementVNode("div", null, [
        (this.pageUI.getIsReady())
          ? (_openBlock(), _createBlock(_component_dp_form, {
              key: 0,
              as: "form",
              onSubmit: _ctx.submitData,
              novalidate: ""
            }, {
              default: _withCtx(({ errors }) => [
                _createVNode(_component_MultilingualSwitcher, {
                  errors: errors,
                  "active-language": this.activeLanguage,
                  onUpdateActiveLanguage: _cache[0] || (_cache[0] = newValue => this.activeLanguage = newValue)
                }, null, 8, ["errors", "active-language"]),
                _createVNode(_component_BaseCard, { class: "fallback text-bg-light border-light mb-3" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseCardBody, { class: "p-2" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('deliveryMethod')), 1),
                          _createElementVNode("div", _hoisted_3, _toDisplayString(this.deliveryMethodDescription), 1)
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('textType')), 1),
                          _createElementVNode("div", _hoisted_6, _toDisplayString(this.textTypeDescription), 1)
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_BaseCard, { class: "fallback text-bg-light border-light mb-3" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BaseCardHeader, { class: "p-2" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t('defaultContent')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_BaseCardBody, { class: "p-2" }, {
                      default: _withCtx(() => [
                        (_ctx.AbilityContext.isNotAuthorizedForFeature('MGMT_FALLBACK_CONTENT') && this.detailModel?.fallback_content !== null && this.detailModel?.fallback_content !== undefined)
                          ? (_openBlock(), _createBlock(_component_i18n_read_only_html, {
                              key: 0,
                              "active-language": this.activeLanguage,
                              data: this.detailModel.fallback_content,
                              name: "enumeration_fallback_content"
                            }, null, 8, ["active-language", "data"]))
                          : _createCommentVNode("", true),
                        (_ctx.AbilityContext.isAuthorizedForFeature('MGMT_FALLBACK_CONTENT'))
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              (this.detailModel.field_type_code === 'HTML')
                                ? (_openBlock(), _createBlock(_component_i18n_input_text_editor, {
                                    key: 0,
                                    "active-language": this.activeLanguage,
                                    errors: errors,
                                    data: this.detailModel.fallback_content,
                                    "onUpdate:data": _cache[1] || (_cache[1] = ($event: any) => ((this.detailModel.fallback_content) = $event)),
                                    name: "enumeration_fallback_content_text_area"
                                  }, null, 8, ["active-language", "errors", "data"]))
                                : (_openBlock(), _createBlock(_component_i18n_input_text, {
                                    key: 1,
                                    errors: errors,
                                    data: this.detailModel.fallback_content,
                                    "onUpdate:data": _cache[2] || (_cache[2] = ($event: any) => ((this.detailModel.fallback_content) = $event)),
                                    "active-language": this.activeLanguage,
                                    name: "enumeration_fallback_content_text",
                                    placeholder: "Inhoud"
                                  }, null, 8, ["errors", "data", "active-language"])),
                              _createElementVNode("div", _hoisted_8, [
                                (this.statusCode)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                      _createVNode(_component_input_select, {
                                        modelValue: this.statusCode,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.statusCode) = $event)),
                                        name: "status",
                                        selectText: _ctx.$t('selectText'),
                                        options: _ctx.statusOptions,
                                        rules: "required",
                                        errors: errors
                                      }, null, 8, ["modelValue", "selectText", "options", "errors"])
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ], 64))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                (_ctx.AbilityContext.isNotAuthorizedForFeature('MGMT_FALLBACK_CONTENT'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_i18n_input_checkbox_boolean, {
                          "active-language": this.activeLanguage,
                          data: this.overrideFallback,
                          "onUpdate:data": _cache[4] || (_cache[4] = ($event: any) => ((this.overrideFallback) = $event)),
                          errors: errors,
                          name: "override_enumeration_content"
                        }, null, 8, ["active-language", "data", "errors"])
                      ]),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('overwriteWithOwnContent')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.AbilityContext.isNotAuthorizedForFeature('MGMT_FALLBACK_CONTENT') && this.overrideFallbackForCurrentLang)
                  ? (_openBlock(), _createBlock(_component_BaseCard, {
                      key: 1,
                      class: "fallback text-bg-light border-light mb-3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BaseCardHeader, { class: "p-2" }, {
                          default: _withCtx(() => [
                            _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t('ownContent')), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_BaseCardBody, { class: "p-2" }, {
                          default: _withCtx(() => [
                            (this.detailModel.field_type_code === 'HTML')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  (this.detailModel?.content !== null)
                                    ? (_openBlock(), _createBlock(_component_i18n_input_text_editor, {
                                        key: 0,
                                        "active-language": this.activeLanguage,
                                        errors: errors,
                                        data: this.detailModel.content,
                                        "onUpdate:data": _cache[5] || (_cache[5] = ($event: any) => ((this.detailModel.content) = $event)),
                                        name: "enumeration_content"
                                      }, null, 8, ["active-language", "errors", "data"]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  (this.detailModel?.content !== null)
                                    ? (_openBlock(), _createBlock(_component_i18n_input_text, {
                                        key: 0,
                                        errors: errors,
                                        data: this.detailModel.content,
                                        "onUpdate:data": _cache[6] || (_cache[6] = ($event: any) => ((this.detailModel.content) = $event)),
                                        "active-language": this.activeLanguage,
                                        name: "enumeration_content"
                                      }, null, 8, ["errors", "data", "active-language"]))
                                    : _createCommentVNode("", true)
                                ], 64))
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_12, [
                  (this.statusCode)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createVNode(_component_input_select, {
                          modelValue: this.statusCode,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.statusCode) = $event)),
                          name: "status",
                          selectText: _ctx.$t('selectText'),
                          options: _ctx.statusOptions,
                          rules: "required",
                          errors: errors
                        }, null, 8, ["modelValue", "selectText", "options", "errors"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("section", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("button", _hoisted_17, _toDisplayString(_ctx.$t('save')), 1),
                      _createVNode(_component_router_link, {
                        class: "ms-3 btn btn-outline-primary",
                        to: {name: 'delivery-method-texts',}
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["onSubmit"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}