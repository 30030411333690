import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row row-title-meta" }
const _hoisted_2 = { class: "col-auto" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "col-auto" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "row row-title-meta mb-4" }
const _hoisted_8 = { class: "col-auto" }
const _hoisted_9 = ["aria-label"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "d-flex align-items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertError2 = _resolveComponent("AlertError2")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertError2, {
      error: _ctx.pageUI.getError()
    }, null, 8, ["error"]),
    (_ctx.pageUI.isReady())
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(this.class)
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_InputSelect, {
                  modelValue: _ctx.titleMeta.text_horizontal_position_code,
                  "onUpdate:modelValue": [
                    _cache[0] || (_cache[0] = ($event: any) => ((_ctx.titleMeta.text_horizontal_position_code) = $event)),
                    _ctx.onUpdate
                  ],
                  label: _ctx.$t('position'),
                  name: this.name + '_text_horizontal_position_code',
                  selectText: _ctx.$t('position'),
                  options: _ctx.horizPosOptions,
                  "can-clear": !_ctx.fieldsRequired,
                  rules: _ctx.inputRules,
                  errors: _ctx.errors
                }, null, 8, ["modelValue", "label", "name", "selectText", "options", "can-clear", "rules", "errors", "onUpdate:modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_InputSelect, {
                  modelValue: _ctx.titleMeta.font_weight_code,
                  "onUpdate:modelValue": [
                    _cache[1] || (_cache[1] = ($event: any) => ((_ctx.titleMeta.font_weight_code) = $event)),
                    _ctx.onUpdate
                  ],
                  label: _ctx.$t('fontWeight'),
                  name: this.name + '_font_weight_code',
                  selectText: _ctx.$t('fontWeight'),
                  options: _ctx.fontWeightOptions,
                  "can-clear": !_ctx.fieldsRequired,
                  rules: _ctx.inputRules,
                  errors: _ctx.errors
                }, null, 8, ["modelValue", "label", "name", "selectText", "options", "can-clear", "rules", "errors", "onUpdate:modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_InputNumber, {
                modelValue: _ctx.titleMeta.font_size_in_rem,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.titleMeta.font_size_in_rem) = $event)),
                label: _ctx.$t('fontSize'),
                name: this.name + '_font_size_in_rem',
                min: 0,
                step: .01,
                "can-clear": !_ctx.fieldsRequired,
                rules: _ctx.inputRules,
                errors: _ctx.errors,
                mode: "group-end",
                "addon-text": "rem"
              }, null, 8, ["modelValue", "label", "name", "can-clear", "rules", "errors"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", {
                class: "bg-color-buttons btn-group btn-group-sm w-auto mt-3 mb-4",
                role: "group",
                "aria-label": _ctx.$t('colorScheme')
              }, [
                _createElementVNode("button", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleOverwriteColorScheme())),
                  class: _normalizeClass(['btn btn-outline-primary', {'active': !this.overwriteColorScheme}]),
                  type: "button"
                }, _toDisplayString(_ctx.$t('cms.colorStandard')), 3),
                _createElementVNode("button", {
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleOverwriteColorScheme())),
                  class: _normalizeClass(['btn btn-outline-primary', {'active': this.overwriteColorScheme}]),
                  type: "button"
                }, _toDisplayString(_ctx.$t('cms.colorCustom')), 3)
              ], 8, _hoisted_9),
              (!_ctx.overwriteColorScheme)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_InputSelect, {
                      modelValue: _ctx.titleMeta.text_color_code,
                      "onUpdate:modelValue": [
                        _cache[5] || (_cache[5] = ($event: any) => ((_ctx.titleMeta.text_color_code) = $event)),
                        _ctx.onUpdate
                      ],
                      label: _ctx.$t('textColor'),
                      name: this.name + '_text_color_code',
                      selectText: _ctx.$t('textColor'),
                      options: _ctx.textColorOptions,
                      "can-clear": !_ctx.fieldsRequired,
                      rules: _ctx.inputRules,
                      errors: _ctx.errors
                    }, null, 8, ["modelValue", "label", "name", "selectText", "options", "can-clear", "rules", "errors", "onUpdate:modelValue"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.overwriteColorScheme && _ctx.titleMeta.text_color)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_ColorPicker, {
                      color: _ctx.titleMeta.text_color,
                      label: _ctx.$t('color'),
                      name: this.name + '_text_color',
                      onChanged: _ctx.onChangeTextColor
                    }, null, 8, ["color", "label", "name", "onChanged"])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}