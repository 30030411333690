import {SymbolRestDto} from "@/api/pharma-cms-content-block/models";
import {MultilingualRestDtoModel} from "@/models/MultilingualRestDtoModel";
import {SymbolTargetEnum} from "@/api/enums/symbol-target-enum";
import {SymbolHorizontalPositionEnum} from "@/api/enums/symbol-horizontal-position-enum";
import {SymbolVerticalPositionEnum} from "@/api/enums/symbol-vertical-position-enum";
import {MaterialSymbolRestDtoModel} from "@/models/api/pharma-cms-content-block/MaterialSymbolRestDtoModel";

export class SymbolRestDtoModel implements SymbolRestDto {
    material_symbol: MaterialSymbolRestDtoModel;
    symbol_url?: MultilingualRestDtoModel | null;
    symbol_target?: SymbolTargetEnum | null;
    symbol_page_id?: MultilingualRestDtoModel | null;
    horizontal_position_code: SymbolHorizontalPositionEnum;
    vertical_position_code: SymbolVerticalPositionEnum;

    constructor(material_symbol: MaterialSymbolRestDtoModel, horizontal_position_code: SymbolHorizontalPositionEnum, vertical_position_code: SymbolVerticalPositionEnum) {
        this.material_symbol = material_symbol;
        this.horizontal_position_code = horizontal_position_code;
        this.vertical_position_code = vertical_position_code;
    }

    public static createWithDefaults(): SymbolRestDtoModel {
        const model = new this(
            MaterialSymbolRestDtoModel.createWithDefaults(),
            SymbolHorizontalPositionEnum.LEFT,
            SymbolVerticalPositionEnum.CENTER
        );

        return model;
    }
}
