import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a74cb2de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "position-relative d-flex flex-column" }
const _hoisted_2 = { class: "row row-settings" }
const _hoisted_3 = { class: "col-6" }
const _hoisted_4 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_5 = { class: "row selector-content" }
const _hoisted_6 = {
  key: 0,
  class: "col-md-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_SelectedProduct = _resolveComponent("SelectedProduct")!
  const _component_ProductSelectorCriteria = _resolveComponent("ProductSelectorCriteria")!
  const _component_ProductSelectorList = _resolveComponent("ProductSelectorList")!
  const _component_FloatingPanel = _resolveComponent("FloatingPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.selectedProduct)
          ? (_openBlock(), _createBlock(_component_InputText, {
              key: 0,
              modelValue: _ctx.catalogProductsCriteria.search_keyword,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.catalogProductsCriteria.search_keyword) = $event)),
              name: "product_input",
              label: _ctx.singleProduct ? _ctx.$t('product') : _ctx.$t('products'),
              rules: "max:255",
              class: "col-6",
              "can-clear": "",
              errors: _ctx.errors,
              onInput: _ctx.handleKeyWordInput
            }, null, 8, ["modelValue", "label", "errors", "onInput"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.selectedProduct)
      ? (_openBlock(), _createBlock(_component_SelectedProduct, {
          key: 0,
          product: _ctx.selectedProduct,
          onSelectionRemoved: _ctx.removeSelection
        }, null, 8, ["product", "onSelectionRemoved"]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_component_FloatingPanel, {
      visible: _ctx.isSelecting,
      position: { x: 100, y: 40 },
      "max-height": 500,
      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isSelecting = false)),
      key: _ctx.panelKey
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            (!_ctx.advancedSearch)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openAdvancedSearch && _ctx.openAdvancedSearch(...args)))
                }, _toDisplayString(_ctx.$t('advancedSearch')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              type: "button",
              class: "btn-close",
              "aria-label": "Close",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closePanel && _ctx.closePanel(...args)))
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.advancedSearch)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_ProductSelectorCriteria, {
                  modelValue: _ctx.catalogProductsCriteria,
                  "onUpdate:modelValue": [
                    _cache[3] || (_cache[3] = ($event: any) => ((_ctx.catalogProductsCriteria) = $event)),
                    _ctx.doAdvancedSearch
                  ]
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.advancedSearch ? 'col-md-9' : 'col-md-12')
          }, [
            _createVNode(_component_ProductSelectorList, {
              "single-product": _ctx.singleProduct,
              "products-overview": _ctx.catalogProducts,
              errors: _ctx.errors,
              ready: _ctx.selectorUI.isReady(),
              onSelectSingleProduct: _ctx.selectSingleProduct,
              onSelectProducts: _ctx.selectProducts
            }, null, 8, ["single-product", "products-overview", "errors", "ready", "onSelectSingleProduct", "onSelectProducts"])
          ], 2)
        ])
      ]),
      _: 1
    }, 8, ["visible"]))
  ]))
}