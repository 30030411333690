import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row row-symbol" }
const _hoisted_2 = { class: "col-4 col-symbol-preview" }
const _hoisted_3 = { class: "col-8 col-symbol-settings" }
const _hoisted_4 = { class: "row row-position mb-4" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "col-auto" }
const _hoisted_7 = { class: "row row-reference" }
const _hoisted_8 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSpinner = _resolveComponent("BaseSpinner")!
  const _component_PreviewSymbol = _resolveComponent("PreviewSymbol")!
  const _component_MaterialSymbolInputComponent = _resolveComponent("MaterialSymbolInputComponent")!
  const _component_Subtitle = _resolveComponent("Subtitle")!
  const _component_InputSelect = _resolveComponent("InputSelect")!
  const _component_PageLinkPicker = _resolveComponent("PageLinkPicker")!
  const _component_dp_form = _resolveComponent("dp-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseSpinner, {
      spin: !this.isReady
    }, null, 8, ["spin"]),
    (this.isReady)
      ? (_openBlock(), _createBlock(_component_dp_form, {
          key: 0,
          as: "form",
          novalidate: ""
        }, {
          default: _withCtx(({ errors }) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_PreviewSymbol, {
                  symbol: this.contentBlock.symbol
                }, null, 8, ["symbol"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_MaterialSymbolInputComponent, {
                  modelValue: this.contentBlock.symbol.material_symbol,
                  errors: errors,
                  "onUpdate:modelValue": _ctx.onMaterialSymbolUpdate
                }, null, 8, ["modelValue", "errors", "onUpdate:modelValue"]),
                _createVNode(_component_Subtitle, {
                  title: _ctx.$t('cms.alignment')
                }, null, 8, ["title"]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_InputSelect, {
                      modelValue: this.contentBlock.symbol.horizontal_position_code,
                      "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event: any) => ((this.contentBlock.symbol.horizontal_position_code) = $event)),
                        this.onChange
                      ],
                      label: _ctx.$t('cms.horizontal'),
                      name: "horizontal_position_code",
                      selectText: _ctx.$t('selectText'),
                      options: this.enumHorizontalPosition,
                      rules: "required",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "selectText", "options", "errors", "onUpdate:modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_InputSelect, {
                      modelValue: this.contentBlock.symbol.vertical_position_code,
                      "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event: any) => ((this.contentBlock.symbol.vertical_position_code) = $event)),
                        this.onChange
                      ],
                      label: _ctx.$t('cms.vertical'),
                      name: "vertical_position_code",
                      selectText: _ctx.$t('selectText'),
                      options: this.enumVerticalPosition,
                      rules: "required",
                      errors: errors
                    }, null, 8, ["modelValue", "label", "selectText", "options", "errors", "onUpdate:modelValue"])
                  ])
                ]),
                _createVNode(_component_Subtitle, {
                  title: _ctx.$t('reference')
                }, null, 8, ["title"]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_PageLinkPicker, {
                      payload: this.pageLinkPayload,
                      errors: errors,
                      onChanged: this.onPageLinkPickerChanged
                    }, null, 8, ["payload", "errors", "onChanged"])
                  ])
                ])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}