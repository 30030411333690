/**
 * AUTOGENERATED, DO NOT EDIT!
 *
 * https://gitlab.com/digital-pharma-tools/open-api-codegen
 *
 */

import {ContentBlockMetadataRestDto} from ".";
import {TitleMetaDataRestDto} from ".";
import {StatusEnum} from "@/api/enums/status-enum";
import {TitleSizeEnum} from "@/api/enums/title-size-enum";

/**
 * @export
 * @interface ProductFilterContentRestDto
 */
export interface ProductFilterContentRestDto {
    /**
     * The status of this content block.
     * @type {StatusEnum}
     * @memberof ProductFilterContentRestDto
     */
    status_code: StatusEnum;

    /**
     * The sorting value of this content block.
     * @type {number|null}
     * @memberof ProductFilterContentRestDto
     */
    sort_weight?: number|null;

    /**
     * The concrete content of this content block. (Does not contain HTML code.)
     * @type {string|null}
     * @memberof ProductFilterContentRestDto
     */
    title?: string|null;

    /**
     * Determines the size of the title.
     * @type {TitleSizeEnum|null}
     * @memberof ProductFilterContentRestDto
     */
    title_size_code?: TitleSizeEnum|null;

    /**
     * 
     * @type {TitleMetaDataRestDto|null}
     * @memberof ProductFilterContentRestDto
     */
    title_meta?: TitleMetaDataRestDto|null;

    /**
     * The concrete content of this content block. (Can contain HTML code.)
     * @type {string|null}
     * @memberof ProductFilterContentRestDto
     */
    content?: string|null;

    /**
     * The key with which the corresponding products can be retrieved.
     * @type {string|null}
     * @memberof ProductFilterContentRestDto
     */
    product_filter_code?: string|null;

    /**
     * 
     * @type {ContentBlockMetadataRestDto|null}
     * @memberof ProductFilterContentRestDto
     */
    cb_metadata?: ContentBlockMetadataRestDto|null;

}

/**
 * @export
 * @function cloneProductFilterContentRestDtoMetaData
 */
export function cloneProductFilterContentRestDtoMetaData(dto: ProductFilterContentRestDto, source: ProductFilterContentRestDto): void {
    dto.title_size_code = source.title_size_code;
}
