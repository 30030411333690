import {RestService} from "@/services/rest/RestService";
import {TitleSchemeEndpointsApi} from "@/api/pharma-cms-color-scheme";
import {CustomerContext} from "@/context/CustomerContext";
import {TitleMetaDataRestDto} from "@/api/pharma-cms-color-scheme/models";

export class TitleSchemeRestService extends RestService {

    private apiService: TitleSchemeEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new TitleSchemeEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async getTitleScheme(title_size_code: string): Promise<TitleMetaDataRestDto> {
        const response = await this.apiService.getTitleMetaForSize(CustomerContext.getCustomerCode(), title_size_code);

        return response.data;
    }

    public async updateTitleScheme(title_size_code: string, title_meta: TitleMetaDataRestDto): Promise<void> {
        await this.apiService.updateTitleMetaForSize(title_meta, CustomerContext.getCustomerCode(), title_size_code);
    }

    public async resetTitleSchemeToDefaults(title_size_code: string): Promise<void> {
        await this.apiService.titleMetaForSizeToDefaults(CustomerContext.getCustomerCode(), title_size_code);
    }
}
