import {RestService} from "@/services/rest/RestService";
import {BodySchemeEndpointsApi} from "@/api/pharma-cms-color-scheme";
import {CustomerContext} from "@/context/CustomerContext";
import {BodySchemeRestDto} from "@/api/pharma-cms-color-scheme/models";

export class BodySchemeRestService extends RestService {

    private apiService: BodySchemeEndpointsApi;

    protected constructor() {
        super();

        this.apiService = new BodySchemeEndpointsApi(this.getDpAxiosV2Instance(), undefined, this.getConfig().cmsUrl);
    }

    public async getBodyScheme(): Promise<BodySchemeRestDto> {
        const response = await this.apiService.getBodyScheme(CustomerContext.getCustomerCode());

        return response.data;
    }

    public async updateBodyScheme(body_scheme: BodySchemeRestDto): Promise<void> {
        await this.apiService.updateBodyScheme(body_scheme, CustomerContext.getCustomerCode());
    }

    public async resetBodySchemeToDefaults(): Promise<void> {
        await this.apiService.bodySchemeToDefaults(CustomerContext.getCustomerCode());
    }
}
