
import {defineComponent} from "vue";
// btn btn-icon btn-sm btn-warning
export default defineComponent({
  name: "AdvancedPanelSwitcher",
  computed: {
    iconIsAdvanced() : String {
      return "discover_tune"
    },
    iconIsNotAdvanced() : String {
      return "instant_mix"
    }
  },
  emits: ['toggleAdvanced'],
  props: {
    isAdvanced: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    class: {
      type: String,
      required: false
    }
  },
  data: () => ({
    isReady: false,
  }),
  mounted() {
    console.log('------------------')
    this.isReady = true;
  },
  methods: {
  }
});
